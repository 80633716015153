import React, {Component}  from 'react';
import moment from "moment";
import axios from 'axios';
import {Link} from "react-router-dom";
// import Countdown from 'react-countdown-now';

import './Informe.css';
import Alert from '../../modal/alert'
import Grabar from '../Historia/Grabador'

class Informege extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula3:'',
        nombre3: '',
        edad3: '',
        fechad3: moment().format('YYYY-MM-DD'),
        diagnostico3:'',
        informe3:'',
        imemodel:[],
        mensajealerta:false,
        grabarOn:false,
        opfirma:'NO',
    }
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }

    guardarcorreo=()=>{
            if (parseFloat(this.state.cedula3) >0) {
                let data = new FormData();
                data.append('cedula', this.state.cedula3);
                data.append('nombre', this.state.nombre3);
                data.append('edad', this.state.edad3);
                data.append('diagnostico', this.state.diagnostico3);
                data.append('desde', this.state.fechad3);
                data.append('tipoinforme', this.state.informe3);
                data.append('firma', this.state.opfirma);
                data.append('boton', 'Adicionar');
                let config = {
                    headers : {
                      'Content-Type' : 'multipart/form-data'
                    }
                }
                const xurl = 'https://fehensa.com/consultorio/informepacientesPHP.php';
                axios.post(xurl, data, config)
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Envio de Correos',
                            cuerpoM: 'El correo se envio exitosamente a el paciente',
                        })
                        
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Envio de Correo',
                            cuerpoM: 'Hubo un error, No se envio el correo a el paciente, verifique por favor',
                        })
                    }
                })
            }else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Envio de Correo',
                    cuerpoM: 'Debe seleccionar un paciente',
                })
            }
    }

    guardar=()=>{
        if (parseFloat(this.state.cedula3) >0 && this.state.informe3.length >0) {
            let data = new FormData();
            data.append('cedu', this.state.cedula3);
            data.append('desde', this.state.fechad3);
            data.append('nota', this.state.diagnostico3);
            data.append('tipoI', this.state.informe3);
            data.append('boton', 'Guardar');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };

            const xurl = 'https://fehensa.com/consultorio/rinfegrePHP.php';
            axios.post(xurl, data, config)

            .then(res => {
                if (res.data.variable1) {
                    this.buscarfechas();
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Informes Medicos',
                        cuerpoM: 'Se guardo el Informe con exito',
                    })
                    
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Quimio X Ciclo',
                        cuerpoM: 'No se guardo la ficha verifique por favor...',
                    })
                }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Quimio X Ciclo',
                cuerpoM: 'Debe seleccionar un paciente',
            })
        }
    }

    limpiar=()=>{
        this.setState({
            cedula3:'',
            nombre3: '',
            edad3: '',
            fechad3: moment().format('YYYY-MM-DD'),
            diagnostico3:'',
            informe3:'',
            imemodel:[],
            grabarOn:false,
            opfirma:'NO',
        })
    }
 
imprimir=()=>{
    if (parseFloat(this.state.cedula3) >0 && this.state.informe3.length >0) {
        let data = new FormData();
        data.append('cedu', this.state.cedula3);
        data.append('nombre', this.state.nombre3);
        data.append('edad', this.state.edad3);
        data.append('nota', this.state.diagnostico3);
        data.append('desde', this.state.fechad3);
        data.append('firma', this.state.opfirma);
        data.append('tipoI', "INFORME MEDICO "+this.state.informe3);
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio/fpdf/reportes/rinfegrePHP.php',
            responseType:'arraybuffer',
            data: data
        })
            .then(function(response) {
            let blob = new Blob([response.data], { type:   'application/pdf' } )
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
            
        });

        // axios.post('https://fehensa.com/clinica/fpdf/reportes/infocartaPHP.php')
        // .then(res => {
        //     console.log(res.data)
        
        // })    
    }else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Informe Medico',
            cuerpoM: 'Debe haber un paciente seleccionado y debe seleccionar el tipo de informe.',
         })
    }
}

buscarfechas=()=>{
    axios.post('https://fehensa.com/consultorio/rinfegrePHP.php/?cedu=' + this.state.cedula3 + '&boton=Consultar')
    .then(res => {                
        let ximemodel = res.data
        console.log('loque trae'+ximemodel)
        this.setState({
            imemodel:ximemodel
        });
    })
}

buscarinfo(e){
    if (parseFloat(e.target.value)>0){
        this.setState({
            [e.target.name]: e.target.value
        })

        //console.log('https://fehensa.com/consultorio/rinfegrePHP.php/?cedu=' + this.state.cedula3 + '&codi='+e.target.value+'&boton=ConsultarInforme')
        axios.post('https://fehensa.com/consultorio/rinfegrePHP.php/?cedu=' + this.state.cedula3 + '&codi='+e.target.value+'&boton=ConsultarInforme')
        .then(res => {                
            this.setState({
                diagnostico3: res.data.variable1,
                informe3:res.data.variable2,
            });
        })
    }
}

consultar(e) {
    if (parseFloat(this.state.cedula3) > 0 || parseFloat(e)>0) {
        let jcedul=0;
        if (parseFloat(this.state.cedula3) > 0){
            jcedul=parseFloat(this.state.cedula3);
        } 
        if (parseFloat(e)>0){
            console.log("xpaso1: "+parseFloat(e));
            jcedul=parseFloat(e);
        } 
        axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + jcedul + '&boton=Consultar')
        //console.log(('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula + '&boton=Consultar'))    
        .then(res => {
                if (res.data) {
                    this.setState({
                        clientetrue:false,
                        citaOn:false,
                        cedula3: String(res.data.variable1),
                        nombre3: String(res.data.variable3)+" "+String(res.data.variable4),
                        edad3: String(res.data.variable6),
                    },this.buscarfechas())
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Informe Medico',
                        cuerpoM: 'No se encontro la cedula que se desea consultar, verifique Gracias...',
                     })
                }
            })
    } else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Reposo Medico',
            cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
        })

    }
}

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    handleGrabar=()=>{
        if (this.state.cedula3.length>0){
            if (this.state.grabarOn){
                this.setState({grabarOn:false,})
            }else{
                this.setState({grabarOn:true,})
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }    
    }


  render(){
      return(
          
        <div className="row">  
            <div className="cont-quimioc col-12">

                {this.state.mensajealerta === true &&
                    <Alert
                    tituloMensaje={this.state.tituloM}
                    cuerpoMensaje={this.state.cuerpoM}
                    open={this.state.mensajealerta}
                    handleClose={this.handleClose}
                    />
                } 

                {this.state.grabarOn ===true &&
                       <div className="flotador">
                            <Grabar className="flotador1"
                                cedupaciente={this.state.cedula3}
                                handleGrabar={this.handleGrabar}
                            />
                        </div>
                } 

                <div className="row">
                    <div className="cont-tituloquimioc col-12">    
                        <span className="tituloquimioc">Informe Médico</span>
                        <Link to="/"><span className="titulo-cerrarquimioc">X</span></Link>
                    </div>
                </div>
            
                <div className='row'>
                    <div className="cont-opcion col-3">
                        <label className="opcion">Cedula.</label>
                        <input
                            disabled={this.state.consulto}
                            className="form-control"
                            type="text"
                            name="cedula3"
                            id="cedula3"
                            autoComplete="off"
                            pattern="[0-9] required"
                            value={this.state.cedula3}
                            onChange={this.onChange.bind(this)}
                            onKeyPress={event => { if (event.key === 'Enter') {this.consultar()} }}
                        />
                        <i className="icono-izquierdac fa fa-search" onClick={this.consultar.bind(this)}> </i>
                    </div>
                    <div className="cont-opcion col-1">
                        <label className="opcion" >Edad.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="edad3"
                            id="edad3"
                            onChange={this.onChange.bind(this)}
                            value={this.state.edad3}
                        />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Nombre.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="nombre3"
                            id="nombre3"
                            autoComplete="off"
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombre3}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Diagnostico.</label>
                        <textarea
                            className="form-control"
                            name="diagnostico3"
                            id="diagnostico3"
                            rows="8"
                            cols="135"
                            value={this.state.diagnostico3}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="radioM col-2">
                            <span className='spanradio'>Evolutivo</span>
                            <input
                                type="radio"
                                className="form-radioM"
                                name="informe3"
                                id="informe3"
                                value="EVOLUTIVO"
                                checked={this.state.informe3 === 'EVOLUTIVO'}
                                onChange={this.onChange.bind(this)}
                            />
                            </div>
                            <div className="radioM col-2">
                                <span className='spanradio'>Ingreso</span>
                                <input
                                    type="radio"
                                    className="form-radioM"
                                    name="informe3"
                                    id="informe3"
                                    value="DE EGRESO"
                                    checked={this.state.informe3 === 'DE EGRESO'}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className="radioM col-2">
                                <span className='spanradio'>Quirurgico</span>
                                <input
                                    type="radio"
                                    className="form-radioM"
                                    name="informe3"
                                    id="informe3"
                                    value="QUIRURGICO"
                                    checked={this.state.informe3 === 'QUIRURGICO'}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div className="cont-opcion col-2">
                                <select id="admin" name="admin" value={this.state.admin} onChange={this.buscarinfo.bind(this)}>
                                    <option>--Historial de Informes--</option>
                                    {this.state.imemodel.map((item, i)=>{
                                        return<option key={i} value={item.variable1}>{item.variable2 }</option>
                                    })}
                                </select>
                            </div>                            
                        </div>
                        <div className="representante row">
                            <label className="labelantecedentesescolar">Imprimir Firma. </label>
                            <label className="titulosn">
                                <input
                                    type="radio"
                                    className="opfirmasi"
                                    name="opfirma"
                                    id="opfirma"
                                    value="SI"
                                    checked={this.state.opfirma === 'SI'}
                                    onChange={this.onChange.bind(this)}
                                />
                            SI</label>
                            <label className="titulosn">
                                <input
                                    type="radio"
                                    className="opfirmano"
                                    name="opfirma"
                                    id="opfirma"
                                    value="NO"
                                    checked={this.state.opfirma === 'NO'}
                                    onChange={this.onChange.bind(this)}
                                />
                            NO</label>
                        </div>
                        <div className="row">
                            <div className="botonesgeneral col-12">
                                <button className="botongri" onClick={this.guardar.bind(this)}>Guardar</button>
                                <button className="botongri" onClick={this.guardarcorreo.bind(this)}>Enviar al Correo</button>
                                <button className="botongri" onClick={this.imprimir.bind(this)}>Imprimir</button>                   
                                <button className="botongri" onClick={this.handleGrabar.bind(this)}>Grabar&nbsp;Sonido</button>
                                <button className="botongri" onClick={this.limpiar.bind(this)}>Limpiar</button>                   
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='row'>    
                    <div className="cont-opcion col-2">
                        <label className="opcion" >Fecha.</label>
                        <input  
                            className="form-control"  
                            name="fechad3"
                            id="fechad3"
                            type="date" 
                            value={ moment(this.state.fechad3).format("YYYY-MM-DD") } 
                            onChange={ this.onChange.bind(this) } 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
export default Informege;  