//dependencias
import React, { Component } from 'react';

//componentes
import CitasAction from './Citas'

class Citas extends Component {

    render(){
        return(
            <div>
                <CitasAction />
            </div>
        );
    }

}
export default Citas;