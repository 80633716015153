//dependencias
import React, { Component } from 'react';

//componentes
import ReposomeAction from './Reposome'

class Reposomedico extends Component {

    render(){
        return(
            <div>
                <ReposomeAction />
            </div>
        );
    }

}
export default Reposomedico;