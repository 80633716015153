//dependencias
import React, { Component } from 'react';
import axios from 'axios';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from '@tinymce/tinymce-react';
import {Link} from "react-router-dom";

//componentes
import './Historia.css';
import Alert from '../../modal/alert'
import RegistrarCasos from './RegistrarCasos'
import Grabar from './Grabador'
import Quimioci from './Quimioci'
import Recipe from '../Recipe/Recipe'
//import { array } from 'prop-types';

class HistoriaAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mensajealerta: '',
            tituloM: '',
            cuerpoM: '',
            nhistoria: '',
            fecha: new Date().toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            cedula: '',
            nombre: '',
            apellido: '',
            fnacimiento: '',
            edad: '',
            sexo: '',
            direccionh: '',
            telefonoh: '',
            direcciont: '',
            telefonot: '',
            ocupacion: '',
            referidor: '',
            motivo: '',
            antecedentes: '',
            consulto: '',
            pacientes: [],
            ifechaevo: [],
            antecedentesOn: false,
            examenfOn: false,
            examenf: '',
            impresiond: '',
            impresionOn: false,
            plan: '',
            planOn: false,
            evolucionOn: false,
            evolucion: '',
            editorState: EditorState.createEmpty(),
            textoevo:'',
            banderag:false,
            botonguardarOn:true,
            botonmodiOn:false,
            banderam:true,
            codigotexto:'',
            valorini:'1',
            clientetrue:false,
            cantitem:'0',
            cantpagi:'0',
            paginactiva:'1',
            desde:1,
            hasta:10,
            casosOn:false,
            grabarOn:false,
            correoOn:false,
            correopa: '',
            correotieneOn:false,
            correonotieneOn:true,
            citaOn:false,
            ifechaespera1:[],
            quimiociOn:false,
            recipeOn:false,
           
            
        };
      
    }
    onChange(e) {
       // console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    eliminar(e) {
        if (this.state.cedula.length > 0) {
            //console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula+'&boton=Eliminar')
            axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula + '&boton=Eliminar')
                .then(res => {
                    if (res.data.variable1) {
                        this.limpiar();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'El Paciente fue eliminado con exito...',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'La Cedula del Paciente que intenta Eliminar No se encuentra en los archivos, Verifique la Cedula Gracias...',
                        })
                    }
                })
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }

    }

    modificar(e) {
        if (this.state.cedula.length > 0 && this.state.nombre.length > 0 && this.state.apellido.length > 0) {
            //console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula + '&historia=' + this.state.nhistoria + '&nombre=' + this.state.nombre + '&apellido=' + this.state.apellido + '&edad=' + this.state.edad + '&fechanaci=' + this.state.fnacimiento + '&sexo=' + this.state.sexo + '&telefonohab=' + this.state.telefonoh + '&direccionhab=' + this.state.direccionh + '&ocupacion=' + this.state.ocupacion + '&direcciontrabajo=' + this.state.direcciont + '&telefonotrabajo=' + this.state.telefonot + '&referidor=' + this.state.referidor + '&motivo=' + this.state.motivo + '&comentario=' + this.state.antecedentes + '&boton=Modificar')
            axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula + '&historia=' + this.state.nhistoria + '&nombre=' + this.state.nombre + '&apellido=' + this.state.apellido + '&edad=' + this.state.edad + '&fechanaci=' + this.state.fnacimiento + '&sexo=' + this.state.sexo + '&telefonohab=' + this.state.telefonoh + '&direccionhab=' + this.state.direccionh + '&ocupacion=' + this.state.ocupacion + '&direcciontrabajo=' + this.state.direcciont + '&telefonotrabajo=' + this.state.telefonot + '&referidor=' + this.state.referidor + '&motivo=' + this.state.motivo + '&comentario=' + this.state.antecedentes + '&boton=Modificar')
                .then(res => {
                       // console.log(res.data)
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'El Paciente se Modifico con Exito...',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'Verifique los Datos, No se logro Modificar a el Paciente Gracias',
                        })
                    }
                })
                
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Para Modificar un paciente al menos debe tener los siguientes campos llenos. CEDULA, NOMBRE y APELLIDO  Gracias.',
                message: 'Verifique e ingrese los de nuevo Gracias.',
            })

        }


    }

        
    limpiar(e) {
        this.setState({
            nhistoria: '',
            fecha: new Date().toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            cedula: '',
            nombre: '',
            apellido: '',
            fnacimiento: '',
            edad: '',
            sexo: '',
            direccionh: '',
            telefonoh: '',
            direcciont: '',
            telefonot: '',
            ocupacion: '',
            referidor: '',
            motivo: '',
            antecedentes: '',
            consulto: '',
            antecedentesOn: false,
            impresiond: '',
            impresionOn: false,
            plan: '',
            planOn: false,
            evolucionOn: false,
            evolucion: '',
            editorState: EditorState.createEmpty(),
            ifechaevo: [],
            textoevo:'',
            bandera:false,
            botonguardarOn:true,
            botonmodiOn:false,
            banderam:true,
            codigotexto:'',
            valorini:'1',
            clientetrue:false,
            cantitem:'0',
            cantpagi:'0',
            paginactiva:'1',
            desde:1,
            hasta:10,
            casosOn:false,
            grabarOn:false,
            correoOn:false,
            correotieneOn:false,
            correonotieneOn:true,
            correopa: '',
            quimiociOn:false,
            recipeOn:false,


        })
    }


    llenardatos(res) {
        this.setState({
            cedula: String(res.data.variable1),
            nhistoria: String(res.data.variable2),
            nombre: String(res.data.variable3),
            apellido: String(res.data.variable4),
            fecha: String(res.data.variable5),
            edad: String(res.data.variable6),
            fnacimiento: String(res.data.variable7),
            sexo: String(res.data.variable8),
            telefonoh: String(res.data.variable9),
            direccionh: String(res.data.variable10),
            ocupacion: String(res.data.variable11),
            direcciont: String(res.data.variable12),
            telefonot: String(res.data.variable13),
            referidor: String(res.data.variable14),
            motivo: String(res.data.variable15),
            antecedentes: String(res.data.variable16),
            consulto: 'SI'

        })

    }
    
    

    cerrarlistadocli(){
        this.setState({
            ifechaevo:[],
            clientetrue:false,
        });

    }

    consultarnombre(e) {
        if (this.state.cedula.length === 0 ) {
            let canit=0;
            let resul=0;
            axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.nombre + '&valorini='+ this.state.valorini +'&boton=Consultarnombre')
            //console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.nombre + '&valorini='+ this.state.valorini +'&boton=Consultarnombre')
                    .then(res => {
                    if (res.data) {
                        //console.log("paso:" +res.data)
                        
                        let xfechaevo = res.data
                        //canit = xfechaevo.map(p => p.variable4);
                        canit = xfechaevo.slice(0,1).map(p => p.variable4);
                        if (((parseFloat(canit)/20)>(Math.round(parseFloat(canit)/20)))){
                            resul = Math.round((parseInt(canit)/20))+1
                        }else{resul = Math.round((parseInt(canit)/20))}     
                        
                        
                        this.setState({
                            cantitem:canit,
                            cantpagi:resul,
                            ifechaevo:xfechaevo,
                            clientetrue:true
                        });
                    
                        
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'No se encontraron pacientes con esos datos, verifique Gracias.',
                        })
                    }
                })
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'No se encontraron pacientesDebe Gracias.',
            })

        }
    }


    consultar(e) {
        if (parseFloat(this.state.cedula) > 0 || parseFloat(e)>0) {
            let jcedul=0;
            if (parseFloat(this.state.cedula) > 0){
                jcedul=parseFloat(this.state.cedula);
            } 
            if (parseFloat(e)>0){
                console.log("xpaso1: "+parseFloat(e));
                jcedul=parseFloat(e);
            } 
            axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + jcedul + '&boton=Consultar')
            //console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + jcedul + '&boton=Consultar')
            .then(res => {
                    if (res.data) {
                        this.setState({
                            ifechaevo:[],
                            clientetrue:false,
                            citaOn:false,
                        })
                        this.llenardatos(res);
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'No se encontro la cedula que se desea consultar, verifiquela e introduzcala de nuevo Gracias...',
                         })
                    }
                })
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
            })

        }
    }

    guardar(e) {
        
        if (this.state.cedula.length > 0 && this.state.nombre.length > 0 && this.state.apellido.length > 0) {
            //console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula+'&historia='+this.state.nhistoria+'&nombre='+this.state.nombre+'&apellido='+this.state.apellido+'&edad='+this.state.edad+'&fechanaci='+this.state.fnacimiento+'&sexo='+this.state.sexo+'&telefonohab='+this.state.telefonoh+'&direccionhab='+this.state.direccionh+'&ocupacion='+this.state.ocupacion+'&direcciontrabajo='+this.state.direcciont+'&telefonotrabajo='+this.state.telefonot+'&referidor='+this.state.referidor+'&motivo='+this.state.motivo+'&comentario='+this.state.antecedentes+'&boton=Grabar')
            axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.cedula + '&historia=' + this.state.nhistoria + '&nombre=' + this.state.nombre + '&apellido=' + this.state.apellido + '&edad=' + this.state.edad + '&fechanaci=' + this.state.fnacimiento + '&sexo=' + this.state.sexo + '&telefonohab=' + this.state.telefonoh + '&direccionhab=' + this.state.direccionh + '&ocupacion=' + this.state.ocupacion + '&direcciontrabajo=' + this.state.direcciont + '&telefonotrabajo=' + this.state.telefonot + '&referidor=' + this.state.referidor + '&motivo=' + this.state.motivo + '&comentario=' + this.state.antecedentes + '&boton=Grabar')
                .then(res => {
                    //console.log(res.data)
                    if (res.data.variable1) {
                       // this.llenardatos(res);
                        this.setState({
                            nhistoria: res.data.variable2,
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'El Paciente se Guardo con Exito...',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'Este Paciente ya Existe Verifique los Datos realizando una Consulta Gracias...',
                        })
                    }
                })
                .catch(err => {
                    if (err.response.status === 404) {
                    }

                });

        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Para crear un paciente al menos debe tener los siguientes campos llenos. CEDULA, NOMBRE y APELLIDO  Gracias...',
                message: 'Verifiquela e ingresela de nuevo Gracias...',
            })

        }
    }

    antecedentes() {
        if (this.state.cedula.length>0){
            if (this.state.antecedentesOn) {
                this.setState({ antecedentesOn: false });
            } else {
                this.setState({ antecedentesOn: true });
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    examenfi() {
        if (this.state.examenfOn) {
            this.setState({
                examenfOn: false,
                examenf: '',
            });
        } else {
            if (this.state.cedula.length > 0) {
                this.setState({ examenfOn: true });
                axios.post('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula + '&fisicotexto=&boton=Consultar')
                //console.log('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula + '&fisicotexto=&boton=Consultar')
                    .then(res => {
                        console.log(res.data)
                        if (res.data) {
                            this.setState({ examenf: res.data.variable1 });
                        }
                    })
            } else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Historia Paciente',
                    cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
                })
            }
        }
    }

    grabaref() {

        if (this.state.cedula.length > 0 && this.state.examenf.length>0) {
            this.setState({ examenfOn: true });
            let data = new FormData();
            data.append('cedu', this.state.cedula);
            data.append('fisicotexto', this.state.examenf);
            data.append('boton', 'Guardar');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://fehensa.com/consultorio/fisicoPHP.php';
            axios.post(xurl, data, config)

                //axios.post('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula + '&fisicotexto=' + this.state.examenf + '&boton=Guardar')
                //console.log('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula+'&fisicotexto='+ this.state.examenf+'&boton=Guardar')
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'Se guardo el Examen Fisico con Exito',
                        });
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'No se guardo el Examen Físico verifique',
                        });

                    }
                })
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
            })
        }
    }



    impresion() {
        if (this.state.impresionOn) {
            this.setState({
                impresionOn: false,
                impresiond: '',
            });
        } else {
            if (this.state.cedula.length > 0) {
                this.setState({ impresionOn: true });
                axios.post('https://fehensa.com/consultorio/idPHP.php/?cedu=' + this.state.cedula + '&boton=Consultar')
                    .then(res => {
                        if (res.data) {
                            this.setState({ impresiond: res.data.variable1 });
                        }
                    })
            } else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Historia Paciente',
                    cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias',
                })
            }
        }
    }

    grabarid() {
        if (this.state.cedula.length > 0 && this.state.impresiond.length>0) {
            this.setState({ impresionOn: true });
            let data = new FormData();
            data.append('cedu', this.state.cedula);
            data.append('texto', this.state.impresiond);
            data.append('boton', 'Guardar');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://fehensa.com/consultorio/idPHP.php';
            axios.post(xurl, data, config)
            //axios.post('https://fehensa.com/consultorio/idPHP.php/?cedu=' + this.state.cedula + '&texto=' + this.state.impresiond + '&boton=Guardar')
                //console.log('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula+'&fisicotexto='+ this.state.examenf+'&boton=Guardar')
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'Se guardo la Impresion Diagnostica con Exito',
                        });
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'No se guardo la Impresion Diagnostica verifique',
                        });

                    }
                })
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
            })
        }

    }


    planp() {
        if (this.state.planOn) {
            this.setState({
                planOn: false,
                plan: '',
            });
        } else {
            if (this.state.cedula.length >0) {
                this.setState({ planOn: true });
                axios.post('https://fehensa.com/consultorio/planPHP.php/?cedu=' + this.state.cedula + '&boton=Consultar')
                    .then(res => {
                        if (res.data) {
                            this.setState({ plan: res.data.variable1 });
                        }
                    })
            } else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Historia Paciente',
                    cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias',
                })
            }
        }

    }

    grabarp() {
        if (this.state.cedula.length > 0 && this.state.plan.length>0) {
            this.setState({ planOn: true });
            let data = new FormData();
            data.append('cedu', this.state.cedula);
            data.append('texto', this.state.plan);
            data.append('boton', 'Guardar');
            let config = {
                headers: {
                  'Content-Type': 'text/html',}
            };
            const xurl = 'https://fehensa.com/consultorio/planPHP.php';
            axios.post(xurl, data, config)

            //axios.post('https://fehensa.com/consultorio/planPHP.php/?cedu=' + this.state.cedula + '&texto=' + this.state.plan + '&boton=Guardar')
                //console.log('https://fehensa.com/consultorio/fisicoPHP.php/?cedu=' + this.state.cedula+'&fisicotexto='+ this.state.examenf+'&boton=Guardar')
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'Se guardo el Plan con Exito',
                        });
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Historia Paciente',
                            cuerpoM: 'No se guardo el Plan verifique',
                        });

                    }
                })
        } else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia Paciente',
                cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
            })
        }

    }

    evolucione() {
        if (this.state.cedula.length>0){
            if (this.state.evolucionOn) {
                this.setState({ 
                    evolucionOn: false,
                    ifechaevo:[], 
                    botonguardarOn:true,
                    bandera:false,
                    botonmodiOn:false,
                    banderam:true,
                    textoevo:'',
                });
            } else {

                if (this.state.cedula.length > 0) {
                    axios.post('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&nomb=&codigo=&boton=Consultar')

                    //?codigo=<?php echo $fila['0'] ?>&cedu=<?php echo $variable1 ?>&nomb=<?php echo $variable2 ?>'
                .then(res => {
                            if (res.data) {
                                let xfechaevo = res.data
                                this.setState({
                                    ifechaevo:xfechaevo,
                                });
                            }
                        })
                }
                this.setState({ evolucionOn: true });
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }    
    }

    consultarfecha(e) {
            if (this.state.cedula.length > 0 && parseInt(e)>0) {
                axios.post('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&nomb=&codigo=' + e + '&boton=Consultar')
            .then(res => {
                        if (res.data) {
                            //console.count(res.data.variable1)
                            this.setState({
                                codigotexto:e,
                                botonguardarOn:false,
                                bandera:true,
                                botonmodiOn:true,
                                banderam:false,
                                textoevo: res.data.variable1,
                            });
                        }
                    })
            }
    }

    grabartexto() {
        // console.log("texto: "+this.state.textoevo)
        if (this.state.cedula.length > 0 && this.state.textoevo.length>0) {
            let data = new FormData();
            data.append('cedu', this.state.cedula);
            data.append('texto', this.state.textoevo);
            data.append('nomb', '');
            data.append('codigo', '');
            data.append('boton', 'Nuevo');

            axios({
                method:'post',
                url:'https://fehensa.com/consultorio/evolucionPHP.php',
                data: data
            })
            //axios.post('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&texto='+this.state.textoevo+'&nomb=&codigo=&boton=Nuevo')
            //console.log('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&texto='+this.state.textoevo+'&nomb=&codigo=&boton=Nuevo')
            .then(res => {
                if (res.data.variable1) {

                    if (this.state.cedula.length > 0) {
                        axios.post('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&nomb=&codigo=&boton=Consultar')
        
                        //?codigo=<?php echo $fila['0'] ?>&cedu=<?php echo $variable1 ?>&nomb=<?php echo $variable2 ?>'
                    .then(resu => {
                                if (resu.data) {
                                    let xfechaevo = resu.data
                                    this.setState({
                                        ifechaevo:xfechaevo,
                                    });
                                }
                            })
                    }
        
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Evolucion Paciente',
                        cuerpoM: 'La Nueva Evolucion fue creada con Exito.',
                    });
                }else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Evolucion Paciente',
                        cuerpoM: 'La Nueva Evolucion Tiene problema, no puede crearse verifique por favor.',
                    });

                }
            })
        }

    }

    modificartexto() {
        // console.log("texto: "+this.state.cedula)
        // console.log("texto: "+this.state.textoevo)
        //console.log("texto: "+this.state.codigotexto)
        if (this.state.cedula.length > 0 && this.state.textoevo.length>0 & this.state.codigotexto.length>0) {
            
            let data = new FormData();
            data.append('cedu', this.state.cedula);
            data.append('texto', this.state.textoevo);
            data.append('nomb', '');
            data.append('codigo', '');
            data.append('fechacedu', this.state.codigotexto);
            data.append('boton', 'Nuevo');

            axios({
                method:'post',
                url:'https://fehensa.com/consultorio/evolucionPHP.php',
                data: data
            })
            //axios.post('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&texto='+this.state.textoevo+'&nomb=&codigo=&fechacedu='+this.state.codigotexto+'=&boton=Nuevo')
            //console.log('https://fehensa.com/consultorio/evolucionPHP.php/?cedu=' + this.state.cedula + '&texto='+this.state.textoevo+'&nomb=&codigo=&boton=Nuevo')
            .then(res => {
                if (res.data.variable1) {

                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Evolucion Paciente',
                        cuerpoM: 'La Evolucion Se Modifico con Exito.',
                    });
                }else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Evolucion Paciente',
                        cuerpoM: 'La Evolucion No pudo ser Modificada.',
                    });

                }
            })
        }

    }
    // prueba=()=>{}
    // carta=()=>{}
    
    limpiarevo=()=>{
        this.setState({
            botonguardarOn:true,
            botonmodiOn:false,
            textoevo:'',
            codigotexto:'',
        })
    }

    handleGrabar=()=>{ 
        if (this.state.cedula.length>0){
            if (this.state.grabarOn){
                this.setState({grabarOn:false,})
            }else{
                this.setState({grabarOn:true,})
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }    
    }
    tipobusqueda=()=>{}
    espera=()=>{
        if (this.state.citaOn){
            this.setState({citaOn:false,})
        }else{
            this.setState({citaOn:true,})
            this.buscarpacientesespera();
        }
    }

    buscarpacientesespera=()=>{
        axios.post('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=&cedula=&motivo=&fecha=&boton=BuscarEspera2')
        .then(res => {
            if (res.data) {
                let xifechaespera1 = res.data
                this.setState({
                    ifechaespera1:xifechaespera1,
                })
            }else{
                this.setState({
                    ifechaespera1:[],
                })
            }
        })
    } 

    handleCasos=()=>{
        if (this.state.cedula.length>0){
            if (this.state.casosOn){
                this.setState({casosOn:false,})
            }else{
                this.setState({casosOn:true,})
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    handleEditorChange(textoevo, editor) {
        this.setState({ textoevo });
    }
    
    handleQuimioc=()=>{
        if (this.state.cedula.length>0){
            if (this.state.quimiociOn){
                this.setState({quimiociOn:false,})
            }else{
                this.setState({quimiociOn:true,})
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    handleRecipe=()=>{
        if (this.state.cedula.length>0){
            if (this.state.recipeOn){
                this.setState({recipeOn:false,})
            }else{
                this.setState({recipeOn:true,})
            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    

    eliminarcorreo() {
        if (this.state.cedula.length>0){
            axios.post('https://fehensa.com/consultorio/correo.php/?cedula=' + this.state.cedula + '&correo=&boton=Eliminar')
            .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Correo Paciente',
                            cuerpoM: 'Se ha Eliminado el correo con exito.',
                            correotieneOn:false,
                            correonotieneOn:true,
                            correopa:'',
                        })
                    }else{
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Correo Paciente',
                            cuerpoM: 'No se ha Eliminado el correo, verifique.',
                        })
                    }
                })

        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    grabarcorreo() {
        if (this.state.cedula.length>0){
            axios.post('https://fehensa.com/consultorio/correo.php/?cedula=' + this.state.cedula + '&correo='+ this.state.correopa +'&boton=Grabar')
            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Correo Paciente',
                        cuerpoM: 'Se ha guardado el correo con exito.',
                        correotieneOn:true,
                        correonotieneOn:false,
                    })
                } else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Correo Paciente',
                        cuerpoM: 'No se guardo el correo, ocurrio un error verifique por favor.',
                    })
                }
            })

        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }

    consultacorreo() {
        if (this.state.cedula.length>0){

            if (this.state.correoOn) {
                this.setState({ correoOn: false });
            } else {
                this.setState({ correoOn: true });
            
                axios.post('https://fehensa.com/consultorio/correo.php/?cedula=' + this.state.cedula + '&correo='+ this.state.correopa + '&boton=Consultar')
                .then(res => {
                    if (res.data.variable1) {
                        this.setState({
                            correopa:res.data.variable1,
                            correotieneOn:true,
                            correonotieneOn:false,
                        })
                    } 
                })

            }
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Historia',
                cuerpoM: 'Debe seleccionar un paciente gracias.',
            })
        }
    }    
    getButtons=()=>{
        let botones=[];
        let bandera=0;
        //console.log(this.state.cantpagi+"-"+this.state.paginactiva)
        botones.push(<button className={parseInt(this.state.paginactiva)>10?"botonspanOf":"botonspanocultar"} name="izquierdaS" onClick={this.busquedapagi}>&lt;</button>)

        for(let i=0; i < this.state.cantpagi; i++ ){
            bandera=0;
            if (((i+1)>=parseInt(this.state.desde)) && ((i+1)<=parseInt(this.state.hasta))){bandera=1;}
                botones.push(<button className={parseInt(this.state.paginactiva)===(i+1)?"botonspanOf":"botonspan"} name={parseInt(i)+1} onClick={this.busquedapagi} id={bandera===0 ? "ocultar" : ""} >{parseInt(i)+1}</button>)
            
        }

        botones.push(<button className={parseInt(this.state.cantpagi)>10 ? "botonspanOf" : "botonspanocultar"} name="derechaS" onClick={this.busquedapagi}>&gt;</button>)
        botones.push(<button className={parseInt(this.state.cantpagi)>10 ? "botonspanOf" : "botonspanocultar"} name="derechaD" onClick={this.busquedapagi}>&gt;&gt;</button>)

        return botones;  
    }

    busquedapagi = (e) => {
        let spaso=0;
        let numepag=0;
        let desde1=0;
        let hasta1=0;
        let resulpaghasta=0;
        let resulpagdesde=0;
        let difehasta=0;
        let paso=0;


        if (e.target.name==="izquierdaS"){
            spaso=1;
            numepag=parseInt(this.state.paginactiva)-1;
            if (numepag===0)numepag=1;
        }
        if (e.target.name==="derechaS"){
            spaso=1;
            numepag=parseInt(this.state.paginactiva)+1;
            if (numepag>parseInt(this.state.cantpagi))numepag=parseInt(this.state.cantpagi)
        }
        if (e.target.name==="derechaD"){
            spaso=1;
            numepag=parseInt(this.state.cantpagi);
        }

        if (parseInt(spaso)===0){numepag=parseInt(e.target.name);}

        resulpaghasta=parseInt(this.state.cantpagi)-numepag;
        
        if (resulpaghasta>=4) {
            hasta1=numepag+4;
            difehasta=0;
        }    
        if (resulpaghasta<4 && resulpaghasta>0) {
            hasta1=numepag+resulpaghasta;
            difehasta=4-resulpaghasta;
        }
        if (resulpaghasta<=0) {
            hasta1=numepag;
            difehasta=4;
        }
        resulpagdesde=numepag-1;
        
        if (resulpagdesde<=0)desde1=1

        if (resulpagdesde<5 && resulpagdesde>0){
            if (difehasta>0){
                if (resulpagdesde<=difehasta){
                    desde1=numepag-resulpagdesde;
                    resulpagdesde=0;
                }    
                if (resulpagdesde>difehasta) {
                    desde1=numepag-difehasta;
                    resulpagdesde=resulpagdesde-difehasta;
                }    
            }
            if (difehasta<=0)desde1=numepag;
        }
        if (resulpagdesde<5 && resulpagdesde>0) desde1=desde1-resulpagdesde;
        
        if (resulpagdesde>=5){
            if (difehasta>0){
                desde1=numepag-difehasta;
                resulpagdesde=resulpagdesde-difehasta;
                paso=1;
            }
            if (difehasta<=0)desde1=numepag-5;

            if (resulpagdesde>=5 && paso===1) desde1=desde1-5;
            if (resulpagdesde>=1 && resulpagdesde<5 && paso===1) desde1=desde1-resulpagdesde;

        }
         
        if (resulpagdesde<5)hasta1=hasta1+(5-resulpagdesde)

        axios.post('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.nombre + '%&valorini='+ numepag +'&boton=Consultarnombre')
        //if (numepag===2) console.log('https://fehensa.com/consultorio/historia.php/?cedula=' + this.state.nombre + '%&valorini='+ numepag +'&boton=Consultarnombre')
        .then(res => {
            if (res.data) {                        
                let xfechaevo = res.data
                this.setState({
                    ifechaevo:xfechaevo,
                    clientetrue:true,
                    paginactiva:numepag,
                    desde:desde1,
                    hasta:hasta1,
                });
            } 
        })
    }
    componentDidMount(){
        navigator.permissions.query({name:'microphone'}).then(function(result) {
            if (result.state === 'granted') {
                //console.log("aprobado")
            } else if (result.state === 'prompt') {
                //console.log("pedir aprobacion")
            } else if (result.state === 'denied') {
                //console.log("denegado")
            }
            // result.onchange = function() {
          
            // };
          });
    }

   
    render() {
        return (

            <div className='main row'>
                {this.state.mensajealerta === true &&
                    <Alert
                        tituloMensaje={this.state.tituloM}
                        cuerpoMensaje={this.state.cuerpoM}
                        open={this.state.mensajealerta}
                        handleClose={this.handleClose}
                    />
                }

                {this.state.casosOn === true &&
                <div className="flotador">
                    <RegistrarCasos className="flotador1"
                        cedupaciente={this.state.cedula}
                        handleCasos={this.handleCasos}
                    />
                </div>
                }

                {this.state.grabarOn ===true &&
                       <div className="flotador">
                            <Grabar className="flotador1"
                                cedupaciente={this.state.cedula}
                                handleGrabar={this.handleGrabar}
                            />
                        </div>
                }

                {this.state.quimiociOn === true &&
                    <div className="flotador">
                        <Quimioci className="flotador1"
                            cedupa={this.state.cedula}
                            edadpa={this.state.edad}
                            nombrepa={this.state.nombre+" "+this.state.apellido}
                            handleQuimioc={this.handleQuimioc}
                        />
                    </div>
                }

                {this.state.recipeOn === true &&
                    <div className="flotador">
                        <Recipe className="flotador1"
                            cedupa={this.state.cedula}
                            edadpa={this.state.edad}
                            nombrepa={this.state.nombre+" "+this.state.apellido}
                            nhistopa={this.state.nhistoria}
                            handleRecipe={this.handleRecipe}
                        />
                    </div>
                }

                <div className="cont-historia col-12">

                    <div className="form-group-header col-12">
                        <span className="limpiar"><i className="fas fa-eraser" onClick={this.limpiar.bind(this)}>Limpiar</i></span>
                        <div className="cont-titulo col-9">
                            <h2 className="titulo">Ficha Patronimica</h2>
                        </div>
                        <div className="cont-histo col-2">
                            <label className="fecha">Fecha: {this.state.fecha}</label>
                            <label className="histo">Historia No. {this.state.nhistoria}</label>
                        </div>
                    </div>
                    <div className="historia-contenido">
                        <div className="form-group col-12">
                            <div className="cont-opcion col-3">
                                <label className="opcion">Cedula.</label>

                                <input
                                    disabled={this.state.consulto}
                                    className="form-control"
                                    type="text"
                                    name="cedula"
                                    id="cedula"
                                    autoComplete="off"
                                    pattern="[0-9] required"
                                    value={this.state.cedula}
                                    onChange={this.onChange.bind(this)}
                                    onKeyPress={event => { if (event.key === 'Enter') {this.consultar()} }}
                                />
                                <i className="icono-izquierdac fa fa-search" onClick={this.consultar.bind(this)}> </i>
                            </div>

                            <div className="cont-opcion col-4">
                                <label className="opcion" >Nombre.</label>

                                <input
                                    className="form-control"
                                    type="text"
                                    name="nombre"
                                    id="nombre"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.nombre}
                                    onKeyPress={event => { if (event.key === 'Enter') {this.consultarnombre()} }}
                                />
                                <i className="icono-izquierdan fa fa-search" onClick={this.consultarnombre.bind(this)}> </i>
                            </div>
                            <div className="cont-opcion col-4">
                                <label className="opcion" >Apellido.</label>

                                <input
                                    className="form-control"
                                    type="text"
                                    name="apellido"
                                    id="apellido"
                                    autoComplete="off"
                                    onChange={this.onChange.bind(this)}
                                    value={this.state.apellido}
                                />
                                {/* <i className="icono-izquierdan fa fa-search" onClick={this.consultar.bind(this)}> </i> */}
                            </div>
                        </div>
                        <div className="form-group col-12">
                            <div className="cont-opcion col-3">
                                <label className="opcion" >F/Nacimiento.</label>

                                <input
                                    type="text"
                                    className="form-control"
                                    name="fnacimiento"
                                    id="fnacimiento"
                                    value={this.state.fnacimiento}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Edad.</label>

                                <input
                                    type="text"
                                    className="form-control"
                                    name="edad"
                                    id="edad"
                                    value={this.state.edad}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                            <div className="cont-opcions col-6">
                                <label className="opcions" >Sexo.</label>
                                <div className="radioM col-3">
                                    <span>Masculino</span>
                                    <input
                                        type="radio"
                                        className="form-radioM"
                                        name="sexo"
                                        id="sexo"
                                        value="M"
                                        checked={this.state.sexo === 'M'}
                                        onChange={this.onChange.bind(this)}
                                    />

                                </div>

                                <div className="radioF col-3">
                                    <span>Femenino</span>
                                    <input
                                        type="radio"
                                        className="form-radioF"
                                        name="sexo"
                                        id="sexo"
                                        value="F"
                                        checked={this.state.sexo === 'F'}
                                        onChange={this.onChange.bind(this)}
                                    />

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="historia-contenido">
                        <div className="form-group col-12">
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Direccion.</label>
                            </div>
                            <div className="group col-7">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="direccionh"
                                    id="direccionh"
                                    placeholder="Direccion de Habitacion"
                                    value={this.state.direccionh}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Telefono.</label>
                            </div>
                            <div className="group col-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="telefonoh"
                                    id="telefonoh"
                                    placeholder="Telefono de Habitacion"
                                    value={this.state.telefonoh}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                        </div>
                        <div className="form-group col-12">
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Direccion.</label>
                            </div>
                            <div className="group col-7">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="direcciont"
                                    id="direcciont"
                                    placeholder="Direccion de Trabajo"
                                    value={this.state.direcciont}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Telefono.</label>
                            </div>
                            <div className="group col-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="telefonot"
                                    id="telefonot"
                                    placeholder="Telefono de Trabajo"
                                    value={this.state.telefonot}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                        </div>

                        <div className="form-group col-12">
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Ocupacion.</label>
                            </div>
                            <div className="group col-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="ocupacion"
                                    id="ocupacion"
                                    value={this.state.ocupacion}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Referidor.</label>
                            </div>
                            <div className="group col-5">

                                <input
                                    type="text"
                                    className="form-control"
                                    name="referidor"
                                    id="referidor"
                                    value={this.state.referidor}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>

                        </div>
                        <div className="form-group col-12">
                            <div className="cont-opcion col-1">
                                <label className="opcion" >Motivo.</label>
                            </div>
                            <div className="group col-9">

                                <input
                                    type="text"
                                    className="form-control"
                                    name="motivo"
                                    id="motivo"
                                    value={this.state.motivo}
                                    onChange={this.onChange.bind(this)}
                                />

                            </div>
                        </div>
                    </div>
                    {this.state.antecedentesOn &&
                        <div className="historia-contenidoant">
                            <div className="form-groupef col-12">
                                <div className="groupef" >
                                    <label className="opcion" >Antecedentes.</label>
                                    <textarea

                                        className="form-control"
                                        name="antecedentes"
                                        id="antecedentes"
                                        rows="3"
                                        cols="135"
                                        value={this.state.antecedentes}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="botones col-11">
                                    <div className="acciones">
                                        <button className="boton" onClick={this.modificar.bind(this)}>Modificar</button>
                                        <button className="boton" onClick={this.antecedentes.bind(this)}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.examenfOn &&
                        <div className="historia-contenidoant">
                            <div className="form-groupef col-12">
                                <div className="groupef">
                                    <label className="opcion" >Examen Físico.</label>
                                    <textarea
                                        className="form-control"
                                        name="examenf"
                                        id="examenf"
                                        rows="3"
                                        cols="135"
                                        value={this.state.examenf}
                                        onChange={this.onChange.bind(this)}
                                        //onKeyPress={event => { if (event.key === 'Enter') {this.onChange2()} }}
                                    />
                                </div>
                                <div className="botones col-12">
                                    <div className="acciones">
                                        <button className="boton" onClick={this.grabaref.bind(this)}>Guardar</button>
                                        <button className="boton" onClick={this.examenfi.bind(this)}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.impresionOn &&
                        <div className="historia-contenidoant">
                            <div className="form-groupef col-12">
                                <div className="groupef">
                                    <label className="opcion" >Impresión Diagnostica.</label>
                                    <textarea
                                        className="form-control"
                                        name="impresiond"
                                        id="impresiond"
                                        rows="3"
                                        cols="135"
                                        value={this.state.impresiond}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="botones col-12">
                                    <div className="acciones">
                                        <button className="boton" onClick={this.grabarid.bind(this)}>Guardar</button>
                                        <button className="boton" onClick={this.impresion.bind(this)}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.planOn &&
                        <div className="historia-contenidoant">
                            <div className="form-groupef col-12">
                                <div className="groupef">
                                    <label className="opcion" >Plan.</label>
                                    <textarea
                                        className="form-control"
                                        name="plan"
                                        id="plan"
                                        rows="3"
                                        cols="135"
                                        value={this.state.plan}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="botones col-12">
                                    <div className="acciones">
                                        <button className="boton" onClick={this.grabarp.bind(this)}>Guardar</button>
                                        <button className="boton" onClick={this.planp.bind(this)}>Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.evolucionOn &&
                        <div className="historia-contenidoant"> 
                            <div className="form-groupev col-12">
                                <div className='row'>
                                    <div className="groupev col-8">
                                        <label className="opcion" >Evolucion.</label>
                                        {/* <textarea 
                                            
                                            name="editor"
                                            id="editor" 
                                        ></textarea> */}
                                        <Editor
                                            name="textoevo"
                                            id="textoevo"
                                            
                                            value={this.state.textoevo}
                                            init={{
                                                // selector: '#editor',
                                                encoding: 'UTF-8',
                                                entity_encoding: 'raw',
                                                contextmenu_never_use_native: true,
                                                max_height: 350,
                                                max_width: 650,
                                                height: 300,
                                                width: 650,
                                                custom_ui_selector: '.boton',
                                                menu: {},
                                                toolbar1: 'alignleft | aligncenter | alignright | alignjustify | fontsizeselect | fontselect | formatselect',
                                                toolbar2: 'cut | copy paste | bold italic | underline',
                                                

                                            }}
                                            onEditorChange={this.handleEditorChange.bind(this)}
                                            //onChange={this.onChange.bind(this)}
                                            //onChange={this.handleEditorChange}
                                        />
                                    </div>

                                    <div className="cont-listadopc col-3">

                                        <div className="listadopc">
                                            <label className="opcionev" >Evoluciones Realizadas.</label>
                                            <table className="tabla2pc">
                                                <thead>
                                                    <tr>
                                                        <th className="tituevo">Fecha Evoluciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="cuerpo">
                                                    
                                                    {this.state.ifechaevo.map((item, i) => {
                                                        //console.log("paso "+i);
                        
                                                        return <tr className={i%2 === 0 ? "odd" : ""} key={item.variable1} onClick={this.consultarfecha.bind(this, item.variable1)}><td className="itemtabla" key={item.variable1}>{item.variable2}</td></tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="botones col-8">
                                        <div className="acciones">
                                            <button className={this.state.botonguardarOn?"boton":"botonOf"} disabled={this.state.banderag} onClick={this.grabartexto.bind(this)}>Nuevo</button>
                                            <button className={this.state.botonmodiOn?"boton":"botonOf"} disabled={this.state.banderam} onClick={this.modificartexto.bind(this)}>Modificar</button>
                                            <button className="boton" onClick={this.evolucione.bind(this)}>Cerrar</button>
                                            <button className="boton" onClick={this.limpiarevo.bind(this)}>Limpiar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                    { this.state.clientetrue===true &&    
                        <div className="cont-listadocli col-8">
                            <div className='col-12'>  
                                <div className="cont-p row">
                                    <div className="cedula-paciente col-1">
                                        <label>Cédula.</label>
                                    </div>
                                    <div className="nombre-paciente col-8">
                                        <label >Nombre del Paciente.</label> 
                                    </div>
                                    <div className="cont-cerrar col-2">
                                        <span className='cerrar' onClick={this.cerrarlistadocli.bind(this)}>X</span> 
                                    </div>
                                </div>  
                            </div>
                            <div className="listadocli col-8">
                                <table className="tabla2">
                                    
                                    <tbody className="cuerpo1">
                                        {this.state.ifechaevo.map((item, i)=>{
                                                //console.log("paso "+i);
                                                
                                                return<tr className={i%2 === 0 ? "odd" : ""} key={item.variable1} onClick={this.consultar.bind(this, item.variable1)} ><td width="30px" key={item.variable1}>{item.variable1}</td>
                                                <td width="150px" >{item.variable2 + " "+item.variable3}</td></tr>                                
                                        })}
                                    </tbody>
                                </table>
                            </div>    
                            <div className="col-12">
                                <div className="row">
                                    <div className="cont-botonce col-5">
                                        {this.getButtons()}
                                    </div>                                                   
                                </div>
                            </div>            
                        </div>
                    }

                    { this.state.correoOn === true &&    
                        <div className="cont-correo-principal row">
                            <div className='cont-correo col-6'>  
                                <div className="cont-span row">
                                    <span className="titulocorreo">Crear Correo a Paciente.</span>
                                    <span className="titulo-listcerrarco" onClick={this.consultacorreo.bind(this)}>X</span>
                                </div>
                                <div className="row">
                                    <div className="cont-datos col-12">
                                        <label className="opcionco" >Correo.</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="correopa"
                                            id="correopa"
                                            autoComplete="off"
                                            onChange={this.onChange.bind(this)}
                                            value={this.state.correopa}
                                        />
                                    </div>
                                </div>  
                                <div className="row">
                                    <div className="acciones col-12">
                                        <button className={this.state.correotieneOn?"botonOf":"boton"} disabled={this.state.correotieneOn} onClick={this.grabarcorreo.bind(this)}>Guardar</button>
                                        <button className={this.state.correonotieneOn?"botonOf":"boton"} disabled={this.state.correonotieneOn} onClick={this.eliminarcorreo.bind(this)}>Eliminar</button>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    }

                    {this.state.citaOn === true &&
                            <div className="cont-evogra1 row">
                                <div className="evogra1 col-12">
                                    <span className="titulo-listevo1">Pacientes Citados a el dia de Hoy</span>
                                    <span className="titulo-listcerrar1" onClick={this.espera.bind(this)}>X</span>
                                    <div className="cont-listevo1 row">
                                        <div className="listevo1 col-8">
                                            <table className="tablaespera1">
                                                <thead>
                                                    <tr>
                                                        <th className="tituevo1">Nombre</th>
                                                        <th className="tituevo1">Motivo</th>
                                                        <th className="tituevo1">No.</th>
                                                        <th className="tituevo1"><span className='cerrarme4'></span></th>
                                                     </tr>
                                                </thead>
                                                <tbody className="cuerpoespera1">                                       
                                                    {this.state.ifechaespera1.map((item, i) => {
                                                        return <tr className={i%2 === 0 ? "odd" : ""} key={item.variable1}><td width="150" className="itemtabla1" key={item.variable1}>{item.variable2}</td>
                                                            <td width="250" className="itemtabla1">{item.variable3}</td>                               
                                                            <td width="10" className="itemtabla1">{item.variable4}</td>                               
                                                            <td width="20"><span className='cerrarme5' onClick={this.consultar.bind(this,item.variable1)}></span> </td></tr>                                
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>    
                                    </div>
                                </div>  
                            </div>   
                    }
                    
                    <div className="botones col-12">
                        <div className="acciones">
                            <button className="boton" onClick={this.antecedentes.bind(this)}>Antecedentes</button>
                            <button className="boton" onClick={this.examenfi.bind(this)}>Examen&nbsp;Fisico</button>
                            <button className="boton" onClick={this.impresion.bind(this)}>I.D.</button>
                            <button className="boton" onClick={this.planp.bind(this)}>Plan</button>
                            <button className="boton" onClick={this.evolucione.bind(this)}>Evolucion</button>
                            <button className="boton" onClick={this.consultacorreo.bind(this)}>Correo</button>
                            <button className="boton" onClick={this.handleCasos.bind(this)}>Registrar&nbsp;Casos</button>
                            {/* <button className="boton" onClick={this.handleQuimiose.bind(this)}>Quimio&nbsp;Semanal</button> */}
                            <button className="boton" onClick={this.handleQuimioc.bind(this)}>Quimio&nbsp;X&nbsp;Ciclos</button>
                        </div>
                    </div>
                   
                    
                    <div className="botones col-12">
                        <div className="acciones">
                            <button className="boton" disabled={this.state.consulto} onClick={this.guardar.bind(this)}>Guardar</button>
                            <button className="boton" onClick={this.modificar.bind(this)}>Modificar</button>
                            {/* <button className="boton" onClick={this.eliminar.bind(this)}>Eliminar</button> */}
                            <button className="boton" onClick={this.espera.bind(this)}>Lista&nbsp;de&nbsp;Espera</button>
                            <button className="boton" onClick={this.handleRecipe.bind(this)}>Recipe&nbsp;Médico</button>
                            <button className="boton" onClick={this.handleGrabar.bind(this)}>Grabar&nbsp;Sonido</button>
                            <Link to="/"><button className="boton">Salir</button></Link>
                        </div>
                        {/* <div className="acciones col-1">
                            <button className="boton" onClick={this.consultar.bind(this)}>Consultar</button>
                        </div>
                        <div className="acciones col-1">
                            <button className="boton" onClick={this.modificar.bind(this)}>Modificar</button>
                        </div>
                        <div className="acciones col-1">
                            <button className="boton" onClick={this.eliminar.bind(this)}>Eliminar</button>
                        </div>
                        <div className="acciones col-1">
                            <button className="boton" onClick={this.espera.bind(this)}>ListadeEspera</button>
                        </div>
                        <div className="acciones col-1">
                            <button className="boton" onClick={this.tipobusqueda.bind(this)}>BuscarXNombre</button>
                        </div>
                        <div className="acciones col-1">
                            <button className="boton" onClick={this.limpiar.bind(this)}>LimpiarCampos</button>
                        </div> */}
                   </div>
                </div>
            </div>
        );
    }
}
export default HistoriaAction;