export const saveCliente =(username,clav) => {
    return{
        type: 'SET_CLIENTE',
        usuario: username,
        password: clav
        
    }
}

export const outCliente1 =() => {
    return{
        type: 'OUT_CLIENTE',   
    }
}

export const saveToken =(token) => {
    return{
        type: 'SET_TOKEN',
        token: token
    }
}

export const outToken =() => {
    //console.log('token')
    return{
        type: 'OUT_TOKEN',   
    }
}