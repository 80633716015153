import React, {Component}  from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/ScaleLoader';
import Countdown from 'react-countdown-now';
//import sha256 from 'crypto-js';

import './Grabador.css';
import { ReactMic } from 'react-mic';
import Alert from '../../modal/alert'
// import maximi from '../../imagenes/maximizar.png';

class Grabador extends Component {
  constructor(props){
    super(props);
    this.state = {
      blobObject: null,
      blobURL1:'',
      isRecording: false,
      isPaused: false,
      archivo:'',
      botongrOn:false,
      botongrOn1:false,
      botonrec:false,
      cedupaciente:'',
      evolucionOn:false,
      ifechaevo: [],
      loading: false,
      mensajealerta: false,
      onInicio:false,
      onParar:true,
      hours:0, minutes:0, seconds:0,
      codigohash:'',
      activoOn: false,

     
    }
  }

  startOrPauseRecording= () => {
    this.setState({onInicio:true,onParar:false})
    const { isPaused, isRecording } = this.state
    if(isPaused) {
      this.setState({ isPaused: false })
    } else if(isRecording) {
      this.setState({ isPaused: true })
    } else {
      this.setState({ isRecording: true, botonrec:true, })
    }
  }

  stopRecording= () => {
    this.setState({ isRecording: false, onInicio:false, onParar:true });
  }

  minimizar=()=>{
    if(this.state.activoOn){
      this.setState({
        activoOn: false,
      })
    }else {
      this.setState({
        activoOn: true,
      })

    }
  }

  vaciarblob(){
    this.setState({
      blobObject: null,
      blobURL:'',
      isRecording: false,
      isPaused: false,
      archivo:'',
      botongrOn:false,
      botongrOn1:false,
      botonrec:false,
      evolucionOn:false,
      ifechaevo: [],
      seconds:0,
      onInicio:false,
      onParar:true,

      
    })
}

  onStop= (blobObject) => {
    let  rw='';
    let global = this;
    let request = new XMLHttpRequest();
    request.open('GET', blobObject.blobURL, true);
    request.responseType = 'blob';
    request.onload = function() {
      let reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function(e){ 
        rw=e.target.result;
        global.setState({
          blobURL: blobObject.blobURL,
          archivo:rw,
          botongrOn:true,
          botongrOn1:true,
        })
      }
    };
    request.send();
  }
  
  spineractivar(e,i){
    if (this.state.loading===true){
      this.setState({
        loading:false,
      })
    }else{
      this.setState({
        loading:true,
      })
      if (e==="tabla"){
        this.consultarSonido(i)
      }
      if (e==="guardarsonido"){
        this.guardarSonido()
      }
    }
  }

  Consultar(){
    
    if (this.state.evolucionOn===true){
      this.setState({
        evolucionOn:false,
        ifechaevo: [],
      })
    }else{
      if (parseFloat(this.props.cedupaciente.length) > 0 ) {
          axios.post('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ this.props.cedupaciente + '&boton=ConsultarFechas')
          //console.log('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ this.props.cedupaciente + '&boton=ConsultarFechas')
          .then(res => {
            if (res.data) {
              let xfechaevo = res.data
              this.setState({
                ifechaevo:xfechaevo,
                evolucionOn:true
              });
            }
          })
      }else {
        this.setState({
          mensajealerta: true,
          tituloM: 'Menu Historia Paciente',
          cuerpoM: 'No se encontraron pacientesDebe Gracias.',
        })
      }
    }
  }

  // guardarSonido(){
  //   if (this.state.blobURL1.length<10){
  //     this.setState({
  //       blobURL1:this.state.archivo,
  //       botongrOn1:false,
  //       loading:false
  //     })
  //     console.log('solo bloburl')
  //   }else{
  //     this.setState({
  //       blobURL1:this.state.blobURL1+this.state.archivo,
  //       botongrOn1:false,
  //       loading:false
  //     })
  //     console.log('bloburl + archivo')
  //   }
  // }


  guardarSonido(){
  //axios.post('https://fehensa.com/consultorio/grabarSonido.php/?cedu=7146761&audio='+ this.state.archivo +'&boton=Guardar')

  let xdata = new FormData();
  xdata.append('cedu', this.props.cedupaciente);
  xdata.append('token', '');
  xdata.append('boton', 'Guardar');
  xdata.append('audio',this.state.archivo)
  let config = {
    headers : {
      'Content-Type' : 'multipart/form-data'
    }
  }
  const xurl = 'https://fehensa.com/consultorio/grabarSonido.php';
  axios.post(xurl, xdata, config)
    
    .then(res => { 
      if (res.data.variable1) {           
        this.setState({
          botongrOn1:false,
          mensajealerta: true,
          tituloM: 'Menu Historia Paciente',
          cuerpoM: 'El Sonido se Guardo con Exito',
        },this.setState({loading:false}))
      }else{
        this.setState({
          mensajealerta: true,
          tituloM: 'Menu Historia Paciente',
          cuerpoM: 'Ocurrio un Problema Verifique.',
        })             
      }  
    })
}

consultarSonido(e){

  //axios.post('https://fehensa.com/consultorio/grabarSonido.php/?cedu=7146761&sonido=&boton=Consultar')
  //console.log('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ this.state.cedupaciente +'&sonido=&boton=Consultar')
   let data = new FormData();
   data.append('cedu', e);
   data.append('boton', 'Consultar');
   let config = {
    headers: {
      'Content-Type': 'application/json',}
  };
   const xurl = 'https://fehensa.com/consultorio/grabarSonido.php';
   axios.post(xurl, data, config)
  .then(res => {

    //console.log("paso: "+res.data.variable1) 
    if (res.data.variable1) {
      let audi=res.data.variable1
      this.setState({
        blobURL:audi,
        evolucionOn:false,
        botongrOn:true,
        botonrec:true,
      },this.setState({loading:false}))           
    }else{
      this.setState({
        mensajealerta: true,
        tituloM: 'Menu Historia Paciente',
        cuerpoM: 'Ocurrio un Problema Verifique.',
      })       
    }  
  })

}

eliminar=(e)=>{
  if (parseFloat(e) > 0 ) {
    axios.post('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ e + '&boton=Eliminar')
    .then(res => {
      if (res.data) {
        this.setState({  
          evolucionOn:false,
          ifechaevo: [],
          mensajealerta: true,
          tituloM: 'Menu Sonido Paciente',
          cuerpoM: 'Se elimino con exito el sonido.',
        });
        this.Consultar()
      }
    })
  }
}

handleClose = () => {
  this.setState({ mensajealerta: false })
}

onComplete(){
  this.stopRecording();
}

  render() {
    const { blobURL, isRecording, isPaused, hours, minutes, seconds} = this.state;
    //console.log(this.state.activoOn)
    //console.log(this.state.blobURL1)
    return(
      <div className="containerprin row"> 
        {this.state.activoOn === true &&
            <div className="row">
              <div className="reproducir2 col-12">
                <i className="icono-izquierdan2 far fa-window-maximize" onClick={this.minimizar.bind(this)}> </i>
                <audio className="output2" id="output" controls="controls" preload="auto">
                  <source src={blobURL}/>

                </audio>
              </div>
            </div>
        }  
        {this.state.activoOn === false &&  
          <div className="cont-prin col-6">

            {this.state.mensajealerta === true &&
              <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
              />
            }         
            {this.state.loading === true &&
              <div className="cont-spinner row">
                <div className='spinner col-6'>
                  <RingLoader
                    clasName="spinner"
                    sizeUnit={"px"}
                    size={160}
                    width={19}
                    radius={20}
                    height={160}
                    color={'#48e120'}
                    loading={this.state.loading}
                  />
                </div>
              </div>
            }
            <div className="row">
              <div className="cont-titulogra col-12">
                <span className="titulo-grabador">Grabador de Sonido</span>
                <span className="titulo-listcerrar" onClick={this.props.handleGrabar}>X</span>
                <span className="titulo-listcerrar2" onClick={this.minimizar.bind(this)}>_</span>
              </div>
            </div>   
            <div className="row">
              <div className="grabar col-12 ">
                  <ReactMic
                    record={isRecording}
                    pause={isPaused}
                    className="sound-wave" 
                    audioBitsPerSecond= {240000}
                    //audioBitsPerSecond= {60000}
                    onStop={this.onStop}
                    onStart={this.onStart}
                    onSave={this.onSave}
                    strokeColor="#000000" 
                    backgroundColor="#48e120"
                    mimeType="audio/mp3"
                    // mimeType="audio/wav"
                  />
                  { this.state.onParar &&
                    <Countdown
                      date={Date.now() + 120000}
                      autoStart={false}
                  />
                  }

                  { this.state.onInicio &&
                  <Countdown
                    onComplete={() => this.onComplete()}
                    date={Date.now() + 120000}
                    render={<span>{hours}:{minutes}:{seconds}</span>}
                  />
                  }
              </div>
                
            </div>
            <div className="row">
              <div className="reproducir col-12">
                <audio className="output" id="output" ref="" controls="controls" src={blobURL} ></audio>
              </div>
            </div>
            <div className="row">
              <div className="botonesgra col-12">
                  <button
                    className={this.state.isRecording?"recButtonOff":"recButtonOn"}
                    disabled={this.state.botonrec}
                    onClick={this.startOrPauseRecording}>  
                  </button>
                  <button
                    className={this.state.isRecording?"stopButtonOn":"stopButtonOff"} 
                    disabled={!isRecording}
                    onClick={this.stopRecording}>
                  </button>
                  <button
                    className={this.state.botongrOn?"eliButtonOn":"eliButtonOff"}
                    disabled={!this.state.botongrOn}
                    onClick={this.vaciarblob.bind(this)}>  
                  </button>
              </div>
            </div>
            {this.state.evolucionOn === true &&
              <div className="cont-evogra row">
                <div className="evogra col-12">
                  <span className="titulo-listevo">Listado de Grabaciones</span>
                  <span className="titulo-listcerrar" onClick={this.Consultar.bind(this)}>X</span>
                  <div className="cont-listevogra row">
                    <div className="listevogra col-4">
                      <table className="evotabla2gra">
                        <tbody className="evocuerpo1">
                          {this.state.ifechaevo.map((item, i)=>{
                            return<tr className={i%2 === 0 ? "odd" : ""} key={item.variable1}><td onClick={this.spineractivar.bind(this,"tabla",item.variable1)} key={item.variable1}>{item.variable1}</td>
                                  <td>{item.variable2}</td>
                                  <td width="20px" ><span className='cerrarca' onClick={this.eliminar.bind(this,item.variable1)}></span></td></tr>                                
                                    
                          })}
                        </tbody>
                      </table>
                    </div>    
                  </div>
                </div>  
              </div>   
            }
            <div className="row">
              <div className="botonesgeneral col-12">
                  <button className={this.state.botongrOn1?"botongr":"botongrOff"} disabled={!this.state.botongrOn1} onClick={this.spineractivar.bind(this,"guardarsonido")}>Guardar</button>
                  <button className="botongr" onClick={this.Consultar.bind(this)}>Consultar</button>
                  {/* <button className="botongr" onClick={this.props.handleGrabar}>Cerrar</button> */}
              </div>
            </div>
          </div>   
        } 
      </div>
    );
  }
}

export default Grabador;