//dependencias
import React,{Component} from 'react';
import { connect } from 'react-redux';

//componentes

//imagenes
import logo1 from '../../imagenes/logo3.jpg'


//css
import './Header.css'

class HeaderNav extends Component { 
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        return(
            <div>
                <div className="cont-cabecera row">
                    <div className="col-cabecera col-12">
                    
                        <div className="logo1-cabecera col-2 ">
                            <img className="logo1" src={logo1} alt=""/>
                        </div>
                        <div className="usu-cabecera col-8">
                            {this.props.usuario1.usuario 
                                ? <span className="usuario" >Usuario: {this.props.usuario1.usuario} </span>
                                : <span className="usuario" >Usuario: </span>
                            }
                        </div>
                        <div className="logo1-cabecera col-2 ">
                            <img className="logo1" src={logo1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}

export default connect(mapStateToProps,)(HeaderNav);