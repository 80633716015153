//dependencias
import React, { Component } from 'react';

//componentes
import ConstanciameAction from './Constanciame'

class Constanciame extends Component {

    render(){
        return(
            <div>
                <ConstanciameAction />
            </div>
        );
    }

}
export default Constanciame;