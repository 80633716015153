//dependencias
import React, { Component } from 'react';

//componentes
import HistoriaAction from './Historia'

class Historia extends Component {

    render(){
        return(
            <div>
                <HistoriaAction />
            </div>
        );
    }

}
export default Historia;