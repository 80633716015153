//dependencias
import React, { Component } from 'react';

//componentes
import ReferenciaAction from './Referencia'

class Referencia extends Component {

    render(){
        return(
            <div>
                <ReferenciaAction />
            </div>
        );
    }

}
export default Referencia;