import React, { Component } from 'react';
import axios from 'axios';

//componentes
import Alert from '../../modal/alert'

class RegistrarCasos extends Component {
    constructor(props){
      super(props)
      this.state={
        cedupaciente:'',
        tipoca:'',
        icasos:[],
        icasosper:[],
        mensajealerta:false,
      };
      
    }

    onChange(e) {
      //console.log(e.target.name)
      this.setState({
          [e.target.name]: e.target.value
      })
    }

    handleClose = () => {
      this.setState({ mensajealerta: false })
    }

    guardar=()=>{
      axios.post('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu='+ this.props.cedupaciente +'&codigo='+this.state.tipoca+'&boton=Guardar')
      .then(res => { 
        if (res.data.variable1) {
          this.buscarCasosPersona();               
          this.setState({
            mensajealerta: true,
            tituloM: 'Menu Registrar Casos.',
            cuerpoM: 'El Caso se Registro con exito.',
          });
        }else {
          this.setState({
            mensajealerta: true,
            tituloM: 'Menu Registrar Casos.',
            cuerpoM: 'No se logro registrar el caso, Verifique por favor.',
          });

        }  
      })

    }

    eliminar=(e)=>{
      axios.post('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu='+ this.props.cedupaciente +'&codigo='+e+'&boton=Eliminar')
      .then(res => { 
        if (res.data.variable1) {
          this.buscarCasosPersona();               
          this.setState({
            mensajealerta: true,
            tituloM: 'Menu Registrar Casos.',
            cuerpoM: 'El Caso se Elimino con exito.',
          });
        }else {
          this.setState({
            mensajealerta: true,
            tituloM: 'Menu Registrar Casos.',
            cuerpoM: 'No se logro eliminar el caso, Verifique por favor.',
          });

        }  
      })

    }

    buscarCasosPersona(){
      axios.post('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu='+ this.props.cedupaciente +'&casos=&boton=Consultar')
     //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu='+ this.props.cedupaciente +'&casos=&boton=Consultar')
      .then(res => { 
        if (res.data) {               
          let xicasosper = res.data
          //console.log(res.data)
          this.setState({
              icasosper:xicasosper
          });
        }  
      })
    }    

  buscarCasos(){
      axios.post('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
     //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
      .then(res => { 
        if (res.data) {               
          let xicasos = res.data
          //console.log(res.data)
          this.setState({
              icasos:xicasos
          });
        }  
      })
  }
  componentDidMount(){
    this.buscarCasos();
    this.buscarCasosPersona();
  }
  //onClick={this.props.handleClose}
    render(){
      return (
        <div className="contenedor-casos row">
          <div className="marco-principal col-8">
            {this.state.mensajealerta === true &&
              <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
              />
            }
            <div className="cont-titulo row">
              <div className="titulore col-12">  
                <label className="opcionppreca" >Registrar Casos a Pacientes.</label>
                <span className="titulo-listcerrarca" onClick={this.props.handleCasos}>X</span>
              </div>
            </div>      
            <div className="cont-casos1 row">
              <div className="label-casos1 col-3">  
                    <label className="label1" >Tipo de Casos:</label>
              </div>
              <div className="select-casos1 col-5">  
                <select id="tipoca" name="tipoca" className="form-control" value={this.state.tipoca} onChange={this.onChange.bind(this)}>
                  <option value="">Seleccione..</option>
                  {this.state.icasos.map((item, i)=>{
                    //console.log("paso "+i);
                    return<option key={i} value={item.variable1}>{item.variable1+" - "+item.variable2 }</option>
                  })}
                </select>
              </div> 
              <div className="2">
                <button className="botonmedi"  onClick={this.guardar.bind(this)}>Guardar</button>
              </div> 
            </div>
            <div className="row">
              <div className="cont-tablacasosre col-12">
                <div className="tablascasosre">
                  <table className="tablare">
                    <tbody className="cuerpo">
                      {this.state.icasosper.map((item, i)=>{
                        return<tr className={i%2 === 0 ? "odd" : ""} key={item.variable1}><td width="250px" key={item.variable1}>{item.variable2}</td>
                          <td width="150px" >{item.variable3}</td>
                          <td width="20px" ><span className='cerrarca' onClick={this.eliminar.bind(this,item.variable1)}></span></td></tr>                                
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>  
          </div>
        </div>
      );
    }
  }  
  export default RegistrarCasos;