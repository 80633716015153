import React, {Component}  from 'react';
import moment from "moment";
//import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import axios from 'axios';
// import Countdown from 'react-countdown-now';

import './Recipe.css';
import Alert from '../../modal/alert'
import Indica from './Indicaciones'
import MedicamentoAction from '../Medicamento/Medicamento.js'

class Recipe extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula: this.props.cedupa,
        nombre: this.props.nombrepa,
        edad: this.props.edadpa,
        nhisto:this.props.nhistopa,
        fecha: moment().format('YYYY-MM-DD'),
        idmedicamento:null,
        indicaciones:'',
        idmedi:'',
        labelmedi:'',
        inputValue:'',
        mensajealerta:false,
        indicacionOn:false,
        composicion:'',
        dosificacion:'',
        presentacion:'',
        irecipe:[],
        irecip:[],
        irecipedes:[],
        tipoca:'',
        banderag:true,
        botonguardarOn:false,
        medicamentoOn:false,
        customStyles1 : {
            option: (styles, state) => ({
                ...styles,
                height: '30px',
                color: state.isSelected ? "#028d7b" : '#028d7b',
                backgroundColor: state.isSelected ? "#81F79F" : styles.color,
                borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                "&:hover": {
                color: "#028d7b",
                backgroundColor: "#A9F5D0"
                },
            }),
            control: (styles, state) => ({
                ...styles,
                boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)" : 0,
                borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
                "&:hover": {
                borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
                }
            })
            }
        }
        //const wait = 500; // milliseconds
        //const loadOptions = inputValue => this.getAsyncOptions(inputValue);
        // this.debouncedLoadOptions = _.debounce(loadOptions, wait);    
  }

    handleIndicacerrar=()=>{
        if (this.state.indicacionOn){
            this.setState({indicacionOn:false,})
            this.recipeMedicamentos();
        }else{
            this.setState({indicacionOn:true,})
        }
    }

    handlemedicerrar=()=>{
        if (this.state.medicamentoOn){
            this.setState({
                medicamentoOn:false,

            })
        }else{
            this.setState({medicamentoOn:true,})
        }
    }

  handleClose = () => {
    this.setState({ mensajealerta: false })
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  onChange2(e){
    let xcodireci=e.target.value;
    this.setState({
        [e.target.name]: e.target.value,
        irecipe:[],
        banderag:true,
        botonguardarOn:false,
    });
    if (xcodireci.length>3) {
        let datas = new FormData();
        datas.append('codireci', xcodireci);
        datas.append('boton', 'Consultarecipeguardado');

        let config = {
            headers : {
                'Content-Type': 'text/html'
            }
        }
        
        const xurl = 'https://fehensa.com/consultorio/recipe.php';
        axios.post(xurl, datas, config)
        .then(res => {
            if (res.data) {
                let xirecipe = res.data
                this.setState({
                    irecipe:xirecipe
                });
            }
        })
    }
  }

  onChange1 = (e) => {
     if (e!==null) {
        
        if (e.value===-1) {
            this.setState({medicamentoOn:true,})
        }else{
            
            let datas = new FormData();
            datas.append('medica', e.inputValue);
            datas.append('indica', this.state.indicaciones);
            datas.append('boton', 'Consultaind');

            let config = {
                headers : {
                    'Content-Type': 'text/html'
                }
            }
            
            const xurl = 'https://fehensa.com/consultorio/recipe.php';
            axios.post(xurl, datas, config)
            .then(res => {
                if (res.data) {
                    
                    this.setState({
                        idmedicamento: e.inputValue,
                        indicacionOn:true, 
                        idmedi:e.inputValue,
                        labelmedi:e.label,
                        indicaciones:'',
                        composicion:res.data.composicion,
                        dosificacion:res.data.dosificacion,
                        presentacion:res.data.presentacion,
                    });    
                }
            })
        }
    }
    
  }

    onChange3 = (e) => {
        if (e.length>0) {
            let datas = new FormData();
            datas.append('medica', e);
            datas.append('boton', 'Consultaind');

            let config = {
                headers : {
                    'Content-Type': 'text/html'
                }
            }
            
            const xurl = 'https://fehensa.com/consultorio/recipe.php';
            axios.post(xurl, datas, config)
            .then(res => {
                if (res.data) {
                    //console.log('paso',res.data.composicion)
                    this.setState({
                        idmedicamento: e,
                        indicacionOn:true, 
                        idmedi:e,
                        composicion:res.data.composicion,
                        dosificacion:res.data.dosificacion,
                        presentacion:res.data.presentacion,
                    });    
                }else{
                }
            })
        }
    }
  

  Buscaridmedidestacado(e){
      //console.log(e)
    if (e.length>0) {
        let datas = new FormData();
        datas.append('medica', e);
        datas.append('boton', 'Buscaridmedidestacado');

        let config = {
            headers : {
                'Content-Type': 'text/html'
            }
        }
            
        const xurl = 'https://fehensa.com/consultorio/recipe.php';
        axios.post(xurl, datas, config)
        .then(res => {
            if (res.data) {
                let codigoe=res.data[0].variable1
                this.setState({
                    labelmedi:res.data[0].variable2,
                    indicaciones:res.data[0].variable3, 
                });   
                this.onChange3(codigoe);
            }
        })    
    }

  }

  


  llamarMedicamentos= async (inputValue) => {  
    if (inputValue.length>3) {
            let datas = new FormData();
            datas.append('medica', inputValue);
            datas.append('boton', 'Consultar');

            let config = {
                headers : {
                    'Content-Type': 'text/html'
                }
            }
            const xurl = 'https://fehensa.com/consultorio/recipe.php';
            let res = await axios.post(xurl, datas, config)
                if (res.data.length > 0) {
                    let arr = []
                    for(let x=0;x<res.data.length;x++){
                        arr.push({label:res.data[x].nombremedi, inputValue:res.data[x].idmedi})
                    }
                    return arr
                }else{
                    const arr = [
                        { value: '-1', label: 'Crear Medicamento()' }
                      ]
                    return arr;
                }
    }
}

recipeMedicamentos(){
    this.setState({
        irecipe:[],
    });
    let datas = new FormData();
    datas.append('cedupa', this.state.cedula);
    datas.append('boton', 'Consultarecipe');

    let config = {
        headers : {
            'Content-Type': 'text/html'
        }
    }
    
    const xurl = 'https://fehensa.com/consultorio/recipe.php';
    axios.post(xurl, datas, config)
    .then(res => {
        if (res.data) {
            let xirecipe = res.data
            //console.log(res.data)
            this.setState({
                irecipe:xirecipe,
                banderag:false,
                botonguardarOn:true,
            });
        }else{
            this.setState({
                banderag:true,
                botonguardarOn:false,
            });
        }
    })
}

recipeMedidestacados=()=>{
    this.setState({
        irecipedes:[],
    });
    let datas = new FormData();
    datas.append('boton', 'Consultamedidestacados');

    let config = {
        headers : {
            'Content-Type': 'text/html'
        }
    }
    
    const xurl = 'https://fehensa.com/consultorio/recipe.php';
    axios.post(xurl, datas, config)
    .then(res => {
        if (res.data) {
            let xirecipedes = res.data
            //console.log(res.data)
            this.setState({
                irecipedes:xirecipedes,
            });
        }
    })
}

componentDidMount(){
    this.recipeMedicamentos();
    this.buscarrecipes();
    this.recipeMedidestacados();
}

eliminar =(e)=>{
    //console.log(e)
    if (e.length>0) {
        
        let datas = new FormData();
        datas.append('cedupa', this.state.cedula);
        datas.append('medica', e);
        datas.append('boton', 'Eliminar');

        let config = {
            headers : {
                'Content-Type': 'text/html'
            }
        }
        
        const xurl = 'https://fehensa.com/consultorio/recipe.php';
        axios.post(xurl, datas, config)
        .then(res => {
            if (res.data) {
                this.recipeMedicamentos();
            }else{
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Recipe Medico',
                    cuerpoM: res.data.variable2,
                  })             
            }
        })
    }
}

grabarrecipe = () =>{
    let datas = new FormData();
    datas.append('cedupa', this.state.cedula);
    datas.append('boton', 'Grabarrecipe');

    let config = {
        headers : {
            'Content-Type': 'text/html'
        }
    }
    
    const xurl = 'https://fehensa.com/consultorio/recipe.php';
    axios.post(xurl, datas, config)
    .then(res => {
        if (res.data) {
            this.recipeMedicamentos();
            this.buscarrecipes();
            this.recipeMedidestacados();
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Recipe Medico',
                cuerpoM: res.data.variable2,
            })             
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Recipe Medico',
                cuerpoM: res.data.variable2,
              })             
        }
    })
    
}

buscarrecipes = () => {
    this.setState({
        irecip:[],
        tipoca:''
    });
    let datas = new FormData();
    datas.append('cedupa', this.state.cedula);
    datas.append('boton', 'Buscarrecipe');

    let config = {
        headers : {
            'Content-Type': 'text/html'
        }
    }
    
    const xurl = 'https://fehensa.com/consultorio/recipe.php';
    axios.post(xurl, datas, config)    
    .then(res => { 
      if (res.data) {               
        let xirecip = res.data
        //console.log(res.data)
        this.setState({
            irecip:xirecip
        });
      }  
    })
}
limpiar = () =>{
        this.recipeMedicamentos();
        this.buscarrecipes();
}

imprimir=()=>{

    let data = new FormData();
    data.append('cedula', this.state.cedula);
     data.append('historia', this.state.nhisto); 
     data.append('nombre', this.state.nombre);
    data.append('recipe', JSON.stringify(this.state.irecipe));

    axios({
        method:'post',
        url:'https://fehensa.com/consultorio/fpdf/reportes/recipePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
         
      })
}

  render(){
      //console.log('paspo',this.state.labelmedi)
      //console.log('paso1',this.state.tipoca);
      return(
        <div className="cont-recipec col-12">

            {this.state.mensajealerta === true &&
                <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
                />
            }

            {this.state.indicacionOn ===true &&
                <div className="flotador">
                    <Indica className="flotador1"
                        cedup={this.state.cedula}
                        idmedica={this.state.idmedi}
                        indica={this.state.indicaciones}
                        medica={this.state.labelmedi}
                        composi={this.state.composicion}
                        dosifi={this.state.dosificacion}
                        presen={this.state.presentacion}
                        handleIndicaCerrar={this.handleIndicacerrar }
                    />
                </div>
            }

            {this.state.medicamentoOn ===true &&
                <div className="flotadorre">
                    <MedicamentoAction className="flotador1re"
                        handlemediCerrar={this.handlemedicerrar}
                    />
                </div>
            }

            <div className="row">
                <div className="cont-titulorecipec col-12">    
                    <span className="titulorecipec">Recipe Medico</span>
                    <span className="titulo-cerrarrecipec" onClick={this.props.handleRecipe}>X</span>
                </div>
            </div>
        
            <div className='row'>
                <div className="cont-opcion col-3">
                    <label className="opcion" >Cedula.</label>
                    <input
                        disabled
                        className="form-control"
                        type="text"
                        name="cedula"
                        id="cedula"
                        onChange={this.onChange.bind(this)}
                        value={this.state.cedula}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >Edad.</label>
                    <input
                        disabled
                        className="form-control"
                        type="text"
                        name="edad"
                        id="edad"
                        onChange={this.onChange.bind(this)}
                        value={this.state.edad}
                    />
                </div>
                <div className="cont-opcion col-4">
                    <label className="opcion" >Nombre.</label>
                    <input
                        disabled
                        className="form-control"
                        type="text"
                        name="nombre"
                        id="nombre"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombre}
                    />
                </div>
                <div className="cont-opcion col-2">
                    <label className="opcion" >No. Historia</label>
                    <input
                        disabled
                        className="form-control"
                        type="text"
                        name="nhisto"
                        id="nhisto"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nhisto}
                    />
                </div>
                <div className="cont-opcion col-2">
                    <label className="opcion" >Fecha Recipe.</label>
                    <input  
                        className="form-control"  
                        name="fecha"
                        id="fecha"
                        type="date" 
                        value={ moment(this.state.fecha).format("YYYY-MM-DD") } 
                        onChange={ this.onChange.bind(this) } 
                    />
                </div>
            </div>    
            <div className='row'>
                <div className="cont-opcionc col-6">
                    <div className='row'>
                        <div className="cont-opcion col-12">
                            <label className="opcion" >Medicamento.</label>
                            <AsyncSelect
                                // cacheOptions
                                defaultOptions
                                isClearable
                                styles={this.state.customStyles1}
                                className='idmedicamento'
                                id="idmedicamento"
                                classNamePrefix="select"
                                name="idmedicamento"
                                // defaultValue={this.state.idmedicamento}
                                // value={this.state.idmedicamento}
                                loadOptions={(inputValue) => this.llamarMedicamentos(inputValue)}
                                onChange={this.onChange1}
                            />
                            {/* <Select 
                                 styles={this.state.customStyles1}
                                 className='medicamento'
                                 options={this.state.options}
                            
                            /> */}
                            
                        </div>
                        <div className="cont-opcion col-12">
                            <div className="cont-titulomd">
                                <span className="titulo-recipemd">Medicamentos Destacados.</span>
                            </div>
                            <div className="cont-listreci row">
                                <div className="listreci col-12">
                                    <table className="tablareci" border="1">
                                        <tbody className="evocuerpo1">
                                            {this.state.irecipedes.map((item, i)=>{
                                                return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} onClick={this.Buscaridmedidestacado.bind(this, item.variable1)}><td width="200" key={item.variable1} >{item.variable2}</td>
                                                <td width="600">{item.variable3}</td></tr>                                
                                            })}
                                        </tbody>
                                    </table>
                                </div>    
                            </div>
                        </div>
                    </div>    
                </div>    
                <div className="cont-opcionc col-6">
                    <div className="cont-tablareci row">
                        <div className="cont-opcionr col-12">
                            <div className="cont-titulorecipe">
                                <span className="limpiarre"><i className="fas fa-eraser" onClick={this.limpiar}>Limpiar</i></span>
                                <span className="titulo-recipe">Recipe de Medicamento</span>
                            </div>
                            <div className="cont-listreci row">
                                <div className="listreci col-12">
                                    <table className="tablareci" border="1">
                                        <tbody className="evocuerpo1">
                                            {this.state.irecipe.map((item, i)=>
                                                
                                                    <tr className={i%2 === 0 ? "odd" : ""}  key={i}>
                                                        <td width="200" key={i} >{item.variable2}</td>
                                                        <td width="600">{item.variable3}</td>
                                                        {this.state.botonguardarOn && 
                                                        <td width="20">
                                                            <span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}/>
                                                        </td>
                                                        } 
                                                    </tr>                                
                                            )}
                                        </tbody>
                                    </table>
                                </div>    
                            </div>
                        </div>    
                        <div className="row">
                            <div className="botones col-12">
                                    <div className="acciones">
                                        <button className="boton" onClick={this.imprimir}>Imprimir</button>
                                        <button className={this.state.botonguardarOn?"boton":"botonOf"} disabled={this.state.banderag} onClick={this.grabarrecipe}>Guardar Recipe</button>
                                        <select id="tipoca" name="tipoca" className="botonse" value={this.state.tipoca} onChange={this.onChange2.bind(this)}>
                                            <option value="">Recipes Anteriores.</option>
                                            {this.state.irecip.map((item, i)=>{
                                                //console.log("paso "+i);
                                                return<option key={i} value={item.variable1}>{item.variable2 }</option>
                                            })}
                                        </select>
                                    </div>
                            </div>
                        </div>
                    </div>    
                </div> 
            </div>
        </div>  
      )
  }

}
export default Recipe;  