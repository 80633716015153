//dependencias
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import { render } from '../node_modules/react-snapshot';

//componentes
import store from './store/store';
import App from './components/App'

ReactDOM.render(
// render(
    <Provider store={store}>
         <Router> 
             <App/> 
        </Router> 
    </Provider>,
document.getElementById('root')
);

serviceWorker.unregister();
