import React, { Component } from 'react';

//componentes
import ChatAction from './Chat'

class Chat extends Component {

    render(){
        return(
            <div>
                <ChatAction />
            </div>
        );
    }

}
export default Chat;