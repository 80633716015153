//dependencias
import React, { Component } from 'react';

//componentes
import InformeAction from './Informe'

class Informe extends Component {

    render(){
        return(
            <div>
                <InformeAction />
            </div>
        );
    }

}
export default Informe;