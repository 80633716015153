import React, {Component}  from 'react';
import axios from 'axios';

import './Indicaciones.css';
import Alert from '../../modal/alert'

class Indicaciones extends Component {
  constructor(props){
    super(props);
    this.state = {
      cedupaciente:this.props.cedup,
      indica:'',
      idmedi:this.props.idmedica,
      medica:this.props.medica,
      composi:this.props.composi,
      dosifi:this.props.dosifi,
      presen:this.props.presen,
      dosis:this.props.indica,
      mensajealerta: false,
     
    }
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  Consultar(){
    
    if (this.state.evolucionOn===true){
      this.setState({
        evolucionOn:false,
        ifechaevo: [],
      })
    }else{
      if (parseFloat(this.props.cedupaciente.length) > 0 ) {
          axios.post('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ this.props.cedupaciente + '&boton=ConsultarFechas')
          //console.log('https://fehensa.com/consultorio/grabarSonido.php/?cedu='+ this.props.cedupaciente + '&boton=ConsultarFechas')
          .then(res => {
            if (res.data) {
              let xfechaevo = res.data
              this.setState({
                ifechaevo:xfechaevo,
                evolucionOn:true
              });
            }
          })
      }else {
        this.setState({
          mensajealerta: true,
          tituloM: 'Menu Historia Paciente',
          cuerpoM: 'No se encontraron pacientesDebe Gracias.',
        })
      }
    }
  }

  guardar=()=>{
  let datas = new FormData();
  datas.append('medica', this.state.idmedi);
  datas.append('cedupa', this.state.cedupaciente);
  datas.append('medicamento', this.state.medica);
  datas.append('indica', this.state.dosis);
  datas.append('boton', 'Grabar');

  let config = {
      headers : {
          'Content-Type': 'text/html'
      }
  }
  
  const xurl = 'https://fehensa.com/consultorio/recipe.php';
  axios.post(xurl, datas, config)
  .then(res => { 
    //console.log(res)
    if (res.data.variable1) {           
      this.props.handleIndicaCerrar();
    }else{
      this.setState({
        mensajealerta: true,
        tituloM: 'Menu Recipe Medico',
        cuerpoM: res.data.variable2,
      })             
    }  
  })
}


handleClose = () => {
  this.setState({ mensajealerta: false })
}


  render() {
    
    return(
      <div className="containerindica row"> 
          <div className="cont-indica col-8">

            {this.state.mensajealerta === true &&
              <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
              />
            }

            <div className="row">
              <div className="cont-titulogra col-12">
                {/* <span className="titulo-grabador">Medicamento</span> */}
                <span className="titulo-listcerrar" onClick={this.props.handleIndicaCerrar}>X</span>
              </div>
            </div>   
            <div className="row">
                <div className="titulo-indi col-3 ">
                    <span className="tituloindi">Medicamento:</span>
                </div>
                <div className="nombre-medica col-9 ">
                        <span className="nombremedica">{this.state.medica}</span>
                </div>
                <div className="titulo-indi col-3 ">
                        <span className="tituloindi">Composicion:</span>
                </div>
                <div className="nombre-composicion col-9 ">
                        <span className="nombrecomposicion">{this.state.composi}</span>
                </div>
                <div className="titulo-indi col-3 ">
                        <span className="tituloindi">Presentacion:</span>
                </div>
                <div className="nombre-presen col-9 ">
                        <span className="nombrepresen">{this.state.presen}</span>
                </div>
                <div className="titulo-indi col-3 ">
                        <span className="tituloindi">Dosificacion:</span>
                </div>
                <div className="nombre-dosifi col-9 ">
                        <span className="nombredosifi">{this.state.dosifi}</span>
                </div>
            </div>
            
            <div className="row">
              <div className="indica col-12">
                    <span className="tituloindi">Dosis.</span>
                    <textarea
                        className="form-control"
                        type="text"
                        row="7"
                        name="dosis"
                        id="dosis"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.dosis}
                    />
              </div>
            </div>
            <div className="row">
              <div className="botonesgeneral col-12">
                  <button className="botongr" onClick={this.guardar}>Agregar al Recipe</button>
                  {/* <button className="botongr" >Consultar</button> */}
                  {/* <button className="botongr" onClick={this.props.handleGrabar}>Cerrar</button> */}
              </div>
            </div>
          </div>   
        
      </div>
    );
  }
}

export default Indicaciones;