import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";


//componentes
import './Medicamento.css';
import Alert from '../../modal/alert'

class MedicamentoAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            ifechamedi:[],
            guardarOn:false,
            mensajealerta:false,
            nombremedi:'',
            xindica:'',
            idcodigo:'',
            dosis:'',
            presen:'',
            composi:'',
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    guardar=()=>{
        let datas = new FormData();
        datas.append('nombre', this.state.nombremedi);
        datas.append('indica', this.state.xindica);
        datas.append('composi', this.state.composi);
        datas.append('presen', this.state.presen);
        datas.append('dosis', this.state.dosis);
        datas.append('boton', 'Grabar');

        let config = {
            headers : {
                'Content-Type': 'text/html'
            }
        }
        
        const xurl = 'https://fehensa.com/consultorio/medicamentos.php';
        axios.post(xurl, datas, config)
        .then(res => { 
            if (res.data.variable1) {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Medicamentos',
                    cuerpoM: res.data.variable2,
                });
                this.limpiar();
            }else{
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Medicamentosm',
                    cuerpoM: res.data.variable2,
                });
            }
        })

    }
    limpiar=()=>{
        this.setState({
            nombremedi:'',
            xindica:'',
            idcodigo:'',
            dosis:'',
            presen:'',
            composi:'',
            guardarOn:false,
        })
    }
    modificar=()=>{
        if (parseInt(this.state.idcodigo)>0){
            axios.post('https://fehensa.com/consultorio/medicamentos.php/?codigo='+this.state.idcodigo+'&nombre='+this.state.nombremedi+'&indica='+this.state.indicaciones+'&boton=Modificar')
            //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
            .then(res => { 
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Medicamentos',
                        cuerpoM: 'El medicamento se modifico con exito.',
                    });
                    this.buscarMedicamentos();
                    this.limpiar();
                }else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Medicamentos',
                        cuerpoM: 'No se pudo modificar el medicamento verifique por favor.',
                    });
                }
            })

        }
    }
    eliminar=(e)=>{ 
        if (parseInt(e)){
            axios.post('https://fehensa.com/consultorio/medicamentos.php/?codigo='+e+'&boton=Eliminar')
            //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
            .then(res => { 
                if (res.data.variable1) {               
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Medicamentos',
                        cuerpoM: 'El medicamento fue eliminado con exito.',
                    });
                    this.buscarMedicamentos();
                }else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Medicamentos',
                        cuerpoM: 'El medicamento fue eliminado con exito.',
                    });
                }  
            })
        }
        
    }
    consulta=(e)=>{
        if (parseInt(e)){
            axios.post('https://fehensa.com/consultorio/medicamentos.php/?codigo='+e+'&boton=Consultar')
            //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
            .then(res => { 
                if (res.data) {               
                    this.setState({
                       guardarOn:true,
                       nombremedi: res.data.variable1,
                       indicaciones: res.data.variable2,
                       idcodigo: e,
                    });
                }  
            })
        }
    }
    
    buscarMedicamentos(){
        axios.post('https://fehensa.com/consultorio/medicamentos.php/?nombre=&boton=ArregloBuscar')
        //console.log('https://fehensa.com/consultorio/regiscasoPHP.php/?cedu=&boton=ConsultarCasos')
        .then(res => { 
            if (res.data) {               
                let xifechamedi = res.data
                //console.log(res.data)
                this.setState({
                    ifechamedi:xifechamedi
                });
            }  
        })
    }

    componentDidMount(){
        this.buscarMedicamentos();
      }

    render(){
        return(
            <div className="cont-medicametos-principal row">
                <div className="cont-medicamentos col-12">
                    
                    {this.state.mensajealerta === true &&
                        <Alert
                            tituloMensaje={this.state.tituloM}
                            cuerpoMensaje={this.state.cuerpoM}
                            open={this.state.mensajealerta}
                            handleClose={this.handleClose}
                        />
                    }
                    <div className="row">    
                        <div className="cont-titulomedi col-12">
                            {this.props.handlemediCerrar ?<span className="titulo-listcerrar" onClick={this.props.handlemediCerrar}>X</span>:''}
                            <span className="titulomedi">Maestro de Médicamentos</span>
                        </div>
                        <div className="cont-datosmedi col-12">
                            <div className="row">
                                <div className="datoslabel col-4">
                                    <label className="opcion">Medicamento.</label>
                                    <input 
                                        className="form-control"
                                        type="text"
                                        name="nombremedi"
                                        id="nombremedi"
                                        autoComplete="off"
                                        value={this.state.nombremedi}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div> 
                                <div className="datoslabel col-6">
                                    <label className="opcion">Observaciones.</label>
                                    <textarea
                                        className="form-control"
                                        name="xindica"
                                        id="xindica"
                                        rows="2"
                                        cols="135"
                                        value={this.state.xindica}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>


                                <div className="datoslabel col-4">
                                    <label className="opcion">Composicion.</label>
                                    <textarea
                                        className="form-control"
                                        name="composi"
                                        id="composi"
                                        rows="2"
                                        cols="135"
                                        value={this.state.composi}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div> 
                                <div className="datoslabel col-6">
                                    <label className="opcion">Presentacion.</label>
                                    <textarea
                                        className="form-control"
                                        name="presen"
                                        id="presen"
                                        rows="2"
                                        cols="135"
                                        value={this.state.presen}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="datoslabel col-4">
                                    <label className="opcion">Dosificacion.</label>
                                    <textarea
                                        className="form-control"
                                        name="dosis"
                                        id="dosis"
                                        rows="2"
                                        cols="135"
                                        value={this.state.dosis}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            
                                <div className="accionesmedi col-12">
                                    <button className={this.state.guardarOn?"botonmediOff":"botonmedi"}  disabled={this.state.guardarOn} onClick={this.guardar.bind(this)}>Guardar</button>
                                    <button className={this.state.guardarOn?"botonmedi":"botonmediOff"} disabled={!this.state.guardarOn} onClick={this.modificar.bind(this)}>Modificar</button>
                                    <button className="botonmedi" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                    {this.props.handlemediCerrar ? '' :<Link to="/"><button className="botonmedi">Salir</button></Link>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="cont-titulomedi col-12">
                            <span className="titulomedi">Listado de Medicamentos Creados</span>
                        </div>
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-6">
                                <span className="titulo-medicamento">Medicamento</span>
                                <span className="titulo-eliminar"><span className='cerrarme3'></span></span>
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.ifechamedi.map((item, i)=>{
                                                    return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1}><td width="200" key={item.variable1} onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td>
                                                        <td width="20"><span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}></span> </td></tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div> */}
                    </div>    
                </div>
            </div>
        );
    }
}
export default(MedicamentoAction)