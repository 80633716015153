import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux'

//componentes
import Alert from '../../Mensajes/alert';
import { saveCliente, saveToken} from '../../accion/accion'
import './login.css'


class LoginAction extends Component{

    constructor(props) {
        super(props);
        this.state = {
            redireccionar: false,
            usuario: '',
            clave: '',
            message: '',
            formulario: '',
            mensajealerta: false

        };
            
    }

    registro(e){
         this.setState({
            usuario: '',
            clave: '',
            nombre: '',
            correo: '',

        });
    }

    verificar(e){
        if (this.state.clave.length > 0  && this.state.usuario.length > 0  ) {
            //console.log('https://fehensa.com/consultorio/valida.php/?pas=' + this.state.clave+'&usu=' + this.state.usuario+'&tok=kamiscake.com.ve')
            axios.get('https://fehensa.com/consultorio/valida.php/?pas=' + this.state.clave+'&usu=' + this.state.usuario+'&tok=kamiscake.com.ve')
            .then(res => {
                var clav = String(res.data.claveUsu)
                var username = res.data.nombreUsu
                var tokenp = res.data.token
                if (clav.length===0 & username.length===0 & tokenp==='NO') {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Inicio de Sesion',
                        cuerpoM: 'El Usuario No Exite, Debe registrarse Para Iniciar una Sesion...',
                    })
                }
                if (clav===this.state.clave && tokenp !== 'NO' ) {
                    this.props.saveCliente(username,clav);
                    this.props.saveToken(tokenp);
                    this.setState({
                        redireccionar: true
                    })
                }else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Inicio de Sesion',
                            cuerpoM: 'No se a podido Validar los datos Favor Verificar el Usuario y la Clave Gracias...',
                        })
                }
                
            })
            .catch(err => {
                if (err.response.status === 404) {
                }
                
            });

        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Inicio de Sesion',
                cuerpoM: 'No se puede dejar campos en blanco debe verificar el Usuario o la Clave Gracias...',
                message: 'Debe incluir el Usuario y Cliente',
            })
    
        }
    }


    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault();
      }

      handleClose=()=>{
          this.setState({mensajealerta:false})
      }

    render(){
                
        if (this.state.redireccionar===true){
            return <Redirect to='/' />
        }
        
        if (this.state.formulario==='registrarse'){
            return(<Redirect to='/registro' />);        
        }

        if (this.state.formulario===''){
            return(
                <div>
                    <form onSubmit={this.handleSubmit} >
                       
                        { this.state.mensajealerta===true &&
                                                        
                            <Alert
                                tituloMensaje={this.state.tituloM}
                                cuerpoMensaje={this.state.cuerpoM}
                                open={this.state.mensajealerta}
                                handleClose={this.handleClose}
                            />
            
                        
                        }        
                        <div className="formulario">
                                <h2>Inicio de Sesion</h2>
                                    <input 
                                    value={this.state.usuario} 
                                    onChange={this.onChange.bind(this)} 
                                    type="text" name="usuario" id="usuario"
                                    placeholder="&#128101; Usuario"
                                />
                                    <input 
                                    value={this.state.usuarios} 
                                    onChange={this.onChange.bind(this)} 
                                    type="password" name="clave" id="clave"
                                    placeholder="&#128272; Clave"
                                />

                             
                                <div className="acciones">
                                    <button className="aceptar" onClick={this.verificar.bind(this)}>Aceptar</button>
                                </div>
                            
                            {/* <p>clave: { this.props.usuario1.password}</p><br/>
                            <p>usuario: { this.props.usuario1.usuario }</p><br/>
                            <p>token: { this.props.token.token }</p> */}
                            {/* <p>{JSON.stringify(this.state)}</p> */}
                        </div>
                    
                    </form>

                </div>
            );
        }    
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token,
    }
}

const mapDispatchToProps = {
    saveCliente,
    saveToken

}

export default connect(mapStateToProps,mapDispatchToProps)(LoginAction);