//dependencias
import React, { Component } from 'react';

//componentes
import EstupefacientesAction from './Estupefacientes'

class Estupefacientes extends Component {

    render(){
        return(
            <div>
                <EstupefacientesAction />
            </div>
        );
    }

}
export default Estupefacientes;